<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card" style="width: auto;">
      <header class="modal-card-head">
        <h2 class="modal-card-title"> {{ isEdit ? $t('workout.edit_workout') : $t('workout.plan_workout') }} </h2>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="newWorkout()">
          <b-field grouped>
            <b-field :type="{'is-danger': errors.has('name')}" :message="errors.get('name')">
              <b-input v-model="workout.name" name="name" :placeholder="$t('general.name')" required ref="name"/>
            </b-field>
            <b-button @click="loadWorkout">Load Workout</b-button><br/>
          </b-field>
          <WorkoutSummary v-if="workout.steps.length > 0" v-bind:workout="workout"/>

          <b-field :label="$t('dates.date')" :type="{'is-danger': errors.has('date')}" :message="errors.get('date')">
            <LocalDatePicker v-model="workout.date" ref="date" required/>
          </b-field>

          <b-field :label="$t('general.description')">
            <b-input v-model="workout.description" type="textarea"/>
          </b-field>
          <b-field grouped>
            <b-field :label="$t('metrics.duration')">
              <TimeInput v-model="workout.duration"/>
            </b-field>
            <b-field :label="$t('metrics.load') ">
              <b-input v-model.number="workout.data.load" type="number"/>
            </b-field>
          </b-field>
          <b-field grouped>
            <UnitInput v-model.number="workout.data.distance" unit="distance" :label="$t('metrics.distance')"/> 

            <b-field :label="$t('activity.sport')">
              <b-select v-model="workout.sport">
                <option value="cycling">{{ $t('activity.cycling') }}</option>
                <option value="running">{{ $t('activity.running') }}</option>
                <option value="strength_training">{{ $t('activity.strength_training') }}</option>
                <option value="other">{{ $t('general.other') }}</option>
              </b-select>
            </b-field>
          </b-field>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="isActive=false">{{ $t('general.close') }}</button>
        <button class="button" type="button" @click="newWorkout()">{{ $t('general.send') }}</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import UnitInput from '@/components/UnitInput.vue';
import WorkoutSummary from './WorkoutSummary.vue';
import LocalDatePicker from '@/components/LocalDatePicker.vue';
import TimeInput from '@/components/TimeInput.vue';
import { ObjectID } from 'bson';

export default {
  name: 'planWorkout',
  data() {
    return {
      workout: {
        id: new ObjectID(),
        name: '',
        date: this.LocalDate.new(),
        duration: 0,
        description: '',
        sport: 'cycling',
        steps: [],
        generated: false,
        data: {
          distance: 0,
          load: 0,
        }
      },
      isEdit: false,
      isActive: false,
      errors: new Map(),
    };
  },
  methods: {
    check(){
      this.errors = new Map();
      if(!this.workout.name){
        this.errors.set('name', this.$t('errors.general.enter_name'));
      }
      if(!this.workout.date || this.workout.date < this.LocalDate.today()){
        this.errors.set('date', this.$t('errors.general.invalid_date'));
      }
      return this.errors.size <= 0;
    },

    newWorkout(){
      if(!this.check())
        return;

      this.$store.dispatch((this.isEdit ? 'updateWorkout' : 'newWorkout'), this.workout).then(() =>{
        this.isActive = false;
        this.workout.id = new ObjectID(); //change ID for future workout sent
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
         console.error(err);
      });
    },

    loadWorkout(){
      this.$eventBus.$emit('show-modal-workout-library');
    },

    setWorkout(workout){
      Object.keys(workout).filter(key => key in this.workout).forEach(key => this.workout[key] = workout[key]); //Only assign workout fields that exist in this.workout (no _id, tag, owner, etc..)
    },

    onOpen(data){
      if(!data){
        this.workout.date = this.LocalDate.today();
        return;
      }
      this.workout.date = data.date;
      this.isEdit = data.isEdit == true;
      if(data.workout)
        this.setWorkout(data.workout);
    },
  },
  mounted(){
    if(this.workoutToEdit)
      this.setWorkout(this.workoutToEdit);

    this.$eventBus.$on('workout-selected', workout => {
      this.setWorkout(workout);
    });

    this.$eventBus.$on('show-modal-plan-workout', (data) => {
      this.onOpen(data);
      this.isActive = true;
    });
    this.$eventBus.$on('close-modal-plan-workout', () => {
      this.isActive = false;
    });
  },
  components: {
    UnitInput,
    WorkoutSummary,
    LocalDatePicker,
    TimeInput,
  },
};
</script>

<style scoped>
.planWorkout{
  text-align: center;
}
</style>
