<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card w-auto">
      <header class="modal-card-head">
        <h2 class="modal-card-title">{{ edit_current ? $t('measure.edit_measure') : $t('measure.new_measure') }}</h2>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="newMeasure()">
          <b-field :label="$t('dates.date')" :type="{'is-danger': errors.has('date')}" :message="errors.get('date')">
            <LocalDatePicker v-model="date" ref="date" required/>
          </b-field>
          <b-field :label="$t('metrics.weight')">
            <b-input v-model.number="weight" type="number" step="1"/>
          </b-field>
          <b-field :label="$t('metrics.resting_heart_rate')">
            <b-input v-model.number="resting_heart_rate" type="number" step="1"/>
          </b-field>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="isActive=false">{{ $t('general.close') }}</button>
        <button class="button" type="button" @click="newMeasure()">{{ edit_current ? $t('general.update') : $t('general.send') }}</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import LocalDatePicker from '@/components/LocalDatePicker.vue';

export default {
  name: 'newMeasure',
  data() {
    return {
      date: this.LocalDate.new(),
      weight: 0,
      resting_heart_rate: 0,
      edit_current: false,
      isActive: false,
      errors: new Map(),
    };
  },
  methods: {
    check(){
      this.errors = new Map();
      if(!this.date){
        this.errors.set('date', this.$t('errors.general.invalid_date'));
      }
      return this.errors.size <= 0;
    },

    newMeasure(){
      if(!this.check())
        return;


      var measure = {
        date: this.date,
        weight: this.convertWeight(this.weight || 0),
        resting_heart_rate: this.resting_heart_rate,
      };
      this.$store.dispatch('newMeasure', measure).then(() =>{
        this.isActive = false; //close modal when measure have been successfully sent
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
      });
    },
    setDate(data){
      this.date = (data && data.date) ? data.date : this.LocalDate.today();
      //Get previous Measure data to prefill this measure
      //Previous measure is either the measure with the same date or the last measure before this date
      var previousMeasure;
      if(this.ugetMeasure(this.date)){
        previousMeasure = this.ugetMeasure(this.date);
        this.edit_current = true; //If it's a measure of a same date, then it's an edition of an already existing measure
      }else{
        previousMeasure = this.ugetLastMeasure(this.date);
      }
      if(previousMeasure){
        //fill data
        this.weight = Math.round(this.convertWeightFrom(previousMeasure.weight ? previousMeasure.weight : this.weight));
        this.resting_heart_rate = previousMeasure.resting_heart_rate || this.resting_heart_rate;
      }
    }
  },
  mounted(){
    this.$eventBus.$on('show-modal-new-measure', (data) => {
      this.setDate(data);
      this.isActive = true;
    });
    this.$eventBus.$on('close-modal-new-measure', () => {
      this.isActive = false;
    });
  },
  components:{
    LocalDatePicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
