<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card" style="width: auto;">
      <header class="modal-card-head">
        <h2 class="modal-card-title">{{ $t('activity.rate_activity') }}</h2>
      </header>

      <section class="modal-card-body">
        <h3 class="title is-4">{{ $t('activity.rate_intensity') }}</h3>

        <b-field :label="$t('training_ride.intensity_done')">
          <b-select v-model="intensity">
            <option value=""></option>
            <option value="easy">{{ $t('metrics.easy') }}</option>
            <option value="endurance">{{ $t('metrics.endurance') }}</option>
            <option value="medium">{{ $t('metrics.medium') }}</option>
            <option value="hard">{{ $t('metrics.hard') }}</option>
            <option value="very_hard">{{ $t('metrics.very_hard') }}</option>
          </b-select>
        </b-field>

        <b-button @click.native="validate()" :disabled="!intensity">{{ $t('general.validate') }}</b-button>
      </section>
      <footer class="modal-card-foot">
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { ObjectID } from 'bson';


export default{
  name: 'RateActivity',
  data(){
    return {
      isActive: false,
      activity: null,
      intensity: '',
    };
  },
  methods: {
    setActivity(activity){
      this.activity = activity;
    },

    open(data){
      if(!data || !data.activity){
        return console.error('Rate Activity no data or no activity: ', data);
      }

      let activity = data.activity;
      this.setActivity(activity);
      this.isActive = true;
    },

    close(){
      this.isActive = false;
    },

    async validate(){
      try{
        this.activity.intensity_done = this.intensity;

        await this.$store.dispatch('updateActivity', this.activity);
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
      }
      this.close();
    }
  },
  mounted(){
    this.$eventBus.$on('show-modal-rate-activity', (data) => {
      this.open(data);
    });
    this.$eventBus.$on('close-modal-rate-activity', () => {
      this.close();
    });
  },
  components: {

  },
}
</script>

<style lang="scss" scoped>
</style>
