<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <h2 class="modal-card-title"> {{ $t('activity.new_activity') }} </h2>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="newActivity()">
          <b-field :label="$t('activity.activity_name')" :type="{'is-danger': errors.has('name')}" :message="errors.get('name')">
            <b-input type="text" name="name" v-model="name"/>
          </b-field>
          <div class="columns is-mobile">
            <b-field class="column" :label="$t('dates.date')" :type="{'is-danger': errors.has('date')}" :message="errors.get('date')">
              <LocalDatePicker v-model="date" ref="date" required/>
            </b-field>
            <b-field class="column" :label="$t('dates.time_day')">
              <b-clockpicker auto-switch position="is-bottom-left" icon="clock" v-model="dayTime"/>
            </b-field>
          </div>
          <b-field :label="$t('general.description')">
            <b-input v-model="description" type="textarea"/>
          </b-field>
          <b-field grouped>
            <b-field :label="$t('metrics.duration')">
              <TimeInput v-model="duration"/>
            </b-field>
            <b-field :label="$t('metrics.distance') ">
              <b-input v-model.number="distance" type="number"/> <span class="pl-1 pt-2"><b>{{ distanceUnit() }}</b></span>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field :label="$t('metrics.load') ">
              <b-input v-model.number="load" type="number"/>
            </b-field>
            <b-field :label="$t('activity.rpe') ">
              <b-slider v-model.number="rpe" :min="0" :max="10" ticks/>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field :label="$t('activity.sport')">
              <b-select v-model="type">
                <option value="cycling">{{ $t('activity.cycling') }}</option>
                <option value="running">{{ $t('activity.running') }}</option>
                <option value="strength_training">{{ $t('activity.strength_training') }}</option>
                <option value="other">{{ $t('general.other') }}</option>
              </b-select>
            </b-field>
            <b-field :label="$t('training_ride.intensity_done')">
              <b-select v-model="intensityDone">
                <option value=""></option>
                <option value="easy">{{ $t('metrics.easy') }}</option>
                <option value="endurance">{{ $t('metrics.endurance') }}</option>
                <option value="medium">{{ $t('metrics.medium') }}</option>
                <option value="hard">{{ $t('metrics.hard') }}</option>
                <option value="very_hard">{{ $t('metrics.very_hard') }}</option>
              </b-select>
            </b-field>
          </b-field>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="isActive=false">{{ $t('general.close') }}</button>
        <button class="button" type="button" @click="newActivity()">{{ $t('general.send') }}</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import LocalDatePicker from '@/components/LocalDatePicker.vue';
import TimeInput from '@/components/TimeInput.vue';

export default {
  name: 'addActivity',
  data() {
    return {
      date: this.LocalDate.new(),
      dayTime: new Date(this.$dateOffset(new Date(0))),
      name: '',
      duration: 0,
      description: '',
      rpe: 0,
      distance: 0,
      load: 0,
      type: 'cycling',
      intensityDone: '',
      isActive: false,
      errors: new Map(),
    };
  },
  methods: {
    check(){
      this.errors = new Map();
      if(!this.name){
        this.errors.set('name', this.$t('errors.general.enter_name'));
      }
      if(!this.date || this.date >= this.LocalDate.today().addDay(1)){
        this.errors.set('date', this.$t('errors.general.invalid_date'));
      }
      return this.errors.size <= 0;
    },

    newActivity(){
      if(!this.check())
        return;

      var time = this.dayTime.getTime() - this.$dateOffset(new Date(0));
      var date = this.LocalDate.new(this.date.getTime() + time);
      var timestamp = date.toTimestamp();
      var activity = {
        name: this.name,
        date: date,
        timestamp: timestamp,
        duration: this.duration,
        description: this.description,
        type: this.type,
        intensity_done: this.intensityDone,
        data: { distance: this.convertDistance(this.distance), load: this.load, rpe: this.rpe }
      };
      this.$store.dispatch('newActivity', activity).then(() =>{
        this.isActive = false;
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
         console.error(err);
      });
    },
    setDate(data){
      this.date = (data && data.date && this.LocalDate.today()) ? data.date : this.LocalDate.today();
    },
  },
  mounted(){
    this.$eventBus.$on('show-modal-add-activity', (data) => {
      this.setDate(data);
      this.isActive = true;
      this.intensityDone = '';
    });
    this.$eventBus.$on('close-modal-add-activity', () => {
      this.isActive = false;
    });
  },
  components: {
    LocalDatePicker,
    TimeInput,
  },
};
</script>

<style lang="scss" scoped>
</style>
