<template>
  <div id="modals">
    <AddToCalendar/>
    <PlanTraining/>
    <AddActivity/>
    <EditActivity/>
    <UploadActivity/>
    <NewMeasure/>
    <PlanWorkout/>
    <NewObjective/>
    <NewTrainingRide/>
    <WorkoutExporter/>
    <WorkoutLibraryModal/>
    <ToolsModal/>
    <TrainingTimeModal/>
    <SyncsModal/>
    <ValidateWorkout/>
    <RateActivity/>
  </div>
</template>

<script>
import AddToCalendar from '@/views/calendar/AddToCalendar.vue';
import PlanTraining from '@/views/trainingride/PlanTraining.vue';
import AddActivity from "@/views/activity/AddActivity.vue";
import EditActivity from "@/views/activity/EditActivity.vue";
import UploadActivity from "@/views/activity/UploadActivity.vue";
import NewMeasure from "@/views/measure/NewMeasure.vue";
import PlanWorkout from '@/views/workout/PlanWorkout.vue';
import NewObjective from '@/views/objective/NewObjective.vue';
import NewTrainingRide from '@/views/trainingride/NewTrainingRide.vue';
import WorkoutExporter from '@/views/workout/WorkoutExporter.vue';
import WorkoutLibraryModal from '@/views/workout/WorkoutLibraryModal.vue';
import ToolsModal from '@/views/tools/ToolsModal.vue';
import TrainingTimeModal from '@/views/plan/TrainingTimeModal.vue';
import SyncsModal from './SyncsModal.vue';
import ValidateWorkout from '@/views/plan/ValidateWorkout.vue';
import RateActivity from '@/views/plan/RateActivity.vue';

export default{
  components: {
    AddToCalendar,
    PlanTraining,
    AddActivity,
    EditActivity,
    UploadActivity,
    NewMeasure,
    PlanWorkout,
    NewObjective,
    NewTrainingRide,
    WorkoutExporter,
    WorkoutLibraryModal,
    ToolsModal,
    TrainingTimeModal,
    SyncsModal,
    ValidateWorkout,
    RateActivity,
  },
}
</script>
