<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card" style="width: auto;">
      <header class="modal-card-head">
        <h2 class="modal-card-title">{{ $t('activity.edit_activity') }} </h2>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="newActivity()">
          <b-field :label="$t('activity.activity_name')">
            <b-input v-model="name"/>
          </b-field>
          <b-field :label="$t('dates.date')" :type="{'is-danger': errors.has('date')}" :message="errors.get('date')">
            <LocalDatePicker v-model="date" ref="date" required/>
          </b-field>
          <b-field :label="$t('general.description')">
            <b-input v-model="description" type="textarea"/>
          </b-field>
          <b-field grouped>
            <b-field :label="$t('metrics.load') ">
              <b-input v-model.number="load" type="number"/>
            </b-field>
            <b-field :label="$t('metrics.distance') ">
              <b-input v-model.number="distance" type="number" step="0.01"/> <span class="pl-1 pt-2"><b>{{ distanceUnit() }}</b></span>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field :label="$t('activity.rpe') ">
              <b-slider v-model.number="rpe" :min="0" :max="10" ticks/>
            </b-field>
            <b-field :label="$t('activity.sport')">
              <b-select v-model="type">
                <option value="cycling">{{ $t('activity.cycling') }}</option>
                <option value="running">{{ $t('activity.running') }}</option>
                <option value="strength_training">{{ $t('activity.strength_training') }}</option>
                <option value="other">{{ $t('general.other') }}</option>
              </b-select>
            </b-field>
            <b-field :label="$t('training_ride.intensity_done')">
              <b-select v-model="intensity_done">
                <option value=""></option>
                <option value="easy">{{ $t('metrics.easy') }}</option>
                <option value="endurance">{{ $t('metrics.endurance') }}</option>
                <option value="medium">{{ $t('metrics.medium') }}</option>
                <option value="hard">{{ $t('metrics.hard') }}</option>
                <option value="very_hard">{{ $t('metrics.very_hard') }}</option>
              </b-select>
            </b-field>
          </b-field>

          <b-field grouped>
            <b-tooltip :label="$t('activity.ignore_power_details')" multilined>
              <b-checkbox v-model="ignore_power_data" size="is-small">{{ $t('activity.ignore_power') }}</b-checkbox>
            </b-tooltip>
            <b-tooltip :label="$t('activity.ignore_heart_rate_details')" multilined>
              <b-checkbox v-model="ignore_heart_rate_data" size="is-small">{{ $t('activity.ignore_heart_rate') }}</b-checkbox>
            </b-tooltip>
          </b-field>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="isActive=false">{{ $t('general.close') }}</button>
        <button class="button" type="button" @click="editActivity()">{{ $t('general.send') }}</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import LocalDatePicker from '@/components/LocalDatePicker.vue';

export default {
  name: 'editActivity',
  data() {
    return {
      activity: null,
      name: '',
      description: '',
      distance: 0,
      load: 0,
      rpe: 0,
      type: 'cycling',
      ignore_power_data: false,
      ignore_heart_rate_data: false,
      date: null,
      intensity_done: '',
      isActive: false,
      errors: new Map(),
    };
  },
  methods: {
    editActivity(){
      if(this.name.length > 0)
        this.activity.name = this.name;
      this.activity.description = this.description ? this.description : '';
      this.activity.data.rpe = this.rpe;
      this.activity.type = this.type;
      this.activity.data = this.activity.data || {};
      this.activity.data.load = this.load;
      this.activity.data.distance = this.convertDistance(this.distance);
      this.activity.ignore_power_data = this.ignore_power_data;
      this.activity.ignore_heart_rate_data = this.ignore_heart_rate_data;
      this.activity.date = this.date;
      this.activity.intensity_done = this.intensity_done;



      this.$store.dispatch('updateActivity', this.activity).then(() =>{
        this.isActive = false;
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
         console.error(err);
      });
    },

    setActivity(data){
      this.activity = Object.assign({}, data.activity);
      this.name = this.activity.name;
      this.type = this.activity.type;
      this.description = this.activity.description || '';
      this.rpe = this.activity.data.rpe || 0;
      this.distance = this.convertDistanceFrom(this.activity.data.distance || 0);
      this.load = this.activity.data.load || 0;
      this.ignore_power_data = this.activity.ignore_power_data;
      this.ignore_heart_rate_data = this.activity.ignore_heart_rate_data;
      this.date = this.activity.date;
      this.intensity_done = this.activity.intensity_done;
    },
  },
  watch: {
    ignore_power_data(){
      if(this.ignore_power_data){
        if(this.activity.data.load === this.activity.data.tss)
          this.load = this.activity.data?.hrss || this.load;
      }else{
        this.load = this.activity.data?.tss || this.load;
      }
    },
  },
  mounted(){
    this.$eventBus.$on('show-modal-edit-activity', (data) => {
      this.setActivity(data);
      this.isActive = true;
    });
    this.$eventBus.$on('close-modal-edit-activity', () => {
      this.isActive = false;
    });
  },
  components: {
    LocalDatePicker,
  },
}
</script>

<style lang="scss">
</style>
