//import { render } from './mapImage.js'; //Use codesplitting if possible if render function is reactivated
import { ugetLastModel, userialExist } from '../../helpers/util.js';
const activityFromBuffer = require('@/../shared/activity/activityFromBuffer.js');


function serialCheckCallback(serial){
  return userialExist(serial);
}

function lastModelCall(date){
  return ugetLastModel(date);
}

function renderFunction(lats, longs){
  return null;//render(lats, longs);
}

export default function activityDataFromBuffer(buffer, name, fileType, userInfos){
  return activityFromBuffer(buffer, name, fileType, userInfos, serialCheckCallback, lastModelCall, renderFunction);
}
