/*
 * Parse and transform a fit file content into an activity with activity data
 * Steps:
 * Parse fit buffer data into json data with fitparser
 * Get and check serial
 * Calculate activityData (arrays of data)
 * Create activity json and calculate data from activityData if needed
 * Generate map with render callback
 */
const FitParser = require('fit-file-parser').default;
const activityUtil = require('./activityUtil');
const clonedeep = require('lodash.clonedeep');

//Init fit parser
const fitParser = new FitParser({
   force: true,
   speedUnit: 'km/h',
   lengthUnit: 'km',
   temperatureUnit: 'celsius',
   elapsedRecordField: true,
   mode: 'cascade',
 });

//Parse buffer into json data with fitparser
function parseFit(buffer){
  return new Promise((resolve, reject) => {
    fitParser.parse(buffer, (error, data) => {
      if(error)
        return reject(error);
      if(!data.activity)
        return reject('NOT_ACTIVITY');
      return resolve(data.activity);
    });
  });
}






/****************************/
/** Activity from fit data **/
/****************************/


//Get or calc activity general data
function getAllData(fitData, activityData, lastModel, userInfos){
  var session = fitData.sessions[0]; //TODO: take into account every sessions
  var data = {
    distance: session.total_distance ? session.total_distance : 0
  };
  //Try to get all the data from the fit general data
  if(session.total_calories)
    data.calories = session.total_calories;
  if(session.avg_speed)
    data.avg_speed = session.avg_speed;
  if(session.max_speed)
    data.max_speed = session.max_speed;
  if(session.avg_power)
    data.avg_power = session.avg_power;
  if(session.max_power)
    data.max_power = session.max_power;
  if(session.normalized_power)
    data.normalized_power = session.normalized_power;
  if(session.avg_heart_rate)
    data.avg_heart_rate = session.avg_heart_rate;
  if(session.max_heart_rate)
    data.max_heart_rate = session.max_heart_rate;
  if(session.total_ascent)
    data.total_ascent = Math.round(session.total_ascent*1000);
  if(session.total_descent)
    data.total_descent = Math.round(session.total_descent*1000);
  if(session.avg_cadence)
    data.avg_cadence = session.avg_cadence;
  if(session.max_cadence)
    data.max_cadence = session.max_cadence;

  //If some data are missing, calc it from activityData
  activityUtil.calcGeneralActivityData(data, activityData, lastModel, userInfos);

  return data;
}

//Create the activity object
function generateActivity(fitData, name, date, timestamp, serial, activityData, userInfos, lastModel){

  if(!name) //If no name generate one
    name = activityUtil.dateFormat(date) + ' activity';

  var type = fitData.sports.length > 0 ? fitData.sports[0].sport : 'cycling';

  var activity = {
    timestamp: timestamp,
    date: date,
    type: type,
    name: name,
    duration: fitData.total_timer_time || Math.round(fitData.sessions[0].total_elapsed_time),
    serial_number: serial,
    data: getAllData(fitData, activityData, lastModel, userInfos),
  };
  activityUtil.calcLoad(activity, lastModel, userInfos);
  return activity;
}



/********************************/
/** ActivityData from fit data **/
/********************************/

//Clone a record
//Used to fill data between timer pauses
function cloneRecord(record, timeInc){
  var newRecord = clonedeep(record);
  newRecord.elapsed_time += timeInc;
  newRecord.timer_time += timeInc;
  return newRecord;
}

//Transform data records (from laps and sessions) into array of data
function recordsToArray(fitData){
  var array = [];
  var previousElapsedTime = 0;
  var previousTimerTime = 0;
  for(var session of fitData['sessions']){
    for(var lap of session['laps']){
      for(var record of lap['records']){
        if(array.length > 0){
          var previousRecord = array[array.length-1];
          var timerDiff = record.timer_time - previousRecord.timer_time;
          timerDiff = Math.min(timerDiff, 180); //Max time to be filled is 3min to avoid abuse
          //If timer diff more than 1sec, fill the time difference with cloned data for each seconds
          //This can happen when data (position, hr, speed, etc...) stay the same for a few seconds,
          //it can be stored by the device as only one instance for the whole small duration and that's something we don't want.
          for(var i=0; i<timerDiff-1; i++){
            array.push(cloneRecord(previousRecord, i));
          }
        }
        array.push(record);
      }
    }
  }
  return array;
}


//Check if records contains token
function checkRecord(records, token){
  for(var record of records)
    if(record[token])
      return true;
  return false;
}

//Get array for token from records
//Check true by default, will return an empty array if no token is found in record (if false, no check and an array full of 0 will be returned)
//Call is a callback if a data change is needed
function dataFromRecords(records, token, check = true, call = val => val){
  var array = [];

  if(!check || checkRecord(records, token)){
    for(var record of records){
      array.push(call(record[token] ? record[token] : 0));
    }
  }

  return array;
}

//Calc pause points and length
//Pause happen on device pauses of activity and exact pause points are needed for some data calculation
function calcPause(records){
  var pause = [];
  var i=0;
  var previousTime = 0;
  for(var record of records){
    var elapsed_time = parseInt(record['elapsed_time']);
    var diff = elapsed_time-previousTime;
    if(diff > 1){
      pause.push(i-1);
      pause.push(diff);
    }
    previousTime = elapsed_time;
    i++;
  }
  return pause;
}

//Accumulated power is the work accumulated from the start of the activity
function getAccumulatedPower(activityData, records){
  var accumulated_power = dataFromRecords(records, 'accumulated_power');
  if(accumulated_power.length == 0 && activityData.power.length != 0)
    accumulated_power = activityUtil.calcAccumulatedPower(activityData.power);
  return accumulated_power;
}

//Generate activity data from records
function generateActivityData(fitData){
  var activityData = {};

  var records = recordsToArray(fitData);

  activityData.position_lat = dataFromRecords(records, 'position_lat');
  activityData.position_long = dataFromRecords(records, 'position_long');
  activityData.distance = dataFromRecords(records, 'distance');
  activityData.power = dataFromRecords(records, 'power');
  activityData.accumulated_power = getAccumulatedPower(activityData, records);
  activityData.altitude = dataFromRecords(records, 'altitude', true, alt => alt*1000); //multiply alt by 1000 to get true value
  activityData.speed = dataFromRecords(records, 'speed', false);
  activityData.heart_rate = dataFromRecords(records, 'heart_rate');
  activityData.cadence = dataFromRecords(records, 'cadence');
  activityData.temperature = dataFromRecords(records, 'temperature', true);
  activityData.left_torque_effectiveness = dataFromRecords(records, 'left_torque_effectiveness');
  activityData.right_torque_effectiveness = dataFromRecords(records, 'right_torque_effectiveness');
  activityData.left_pedal_smoothness = dataFromRecords(records, 'left_pedal_smoothness');
  activityData.right_pedal_smoothness = dataFromRecords(records, 'right_pedal_smoothness');
  activityData.left_right_balance = dataFromRecords(records, 'left_right_balance', true, bal => (bal == 0) ? 0 : bal.value);

  if(fitData.hrv){ //If fit file contains hrv, it's not stored in records
    activityData.hrv = []; //TODO: check if hrv is same length as records ?
    fitData.hrv.forEach(hv => {
      activityData.hrv.push(Math.round(hv.time[0]*100));
    });
  }

  activityData.pause = calcPause(records);

  //Calc power and heart rate curves
  activityData.power_curve = activityUtil.powerCurveFromData(activityData.power, activityData.pause);
  activityData.heart_rate_curve = activityUtil.heartRateCurveFromData(activityData.heart_rate, activityData.pause);

  return activityData;
}










module.exports = async function(buffer, name, userInfos, serialCheckCallback = serial => false, lastModelCall = date => null, renderFunction = (lat, long) => null){
  var fitData = await parseFit(buffer);

  var timestamp = new Date(fitData.sessions[0].start_time);//new Date(fitData.timestamp);
  var serial = timestamp.getTime();
  if(serialCheckCallback(serial))
    throw 'errors.activity.duplicate';

  var offset = fitData.timestamp && fitData.local_timestamp ? fitData.timestamp - fitData.local_timestamp : timestamp.getTimezoneOffset();
  if(Math.abs(offset) > 24*3600)
    offset = 0;
  var date = new Date(timestamp.getTime() - offset);

  var activityData = generateActivityData(fitData);
  return { activity: generateActivity(fitData, name, date, timestamp, serial, activityData, userInfos, lastModelCall(date)), activityData: activityData, image: await renderFunction(activityData.position_lat, activityData.position_long) };
}
