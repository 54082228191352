<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <WorkoutLibrary class="modal-card box" :selectionEnabled="true"/>
  </b-modal>
</template>

<script>
import WorkoutLibrary from './WorkoutLibrary.vue';

export default{
  name: 'workoutLibraryModal',
  data() {
    return {
      isActive: false,
    };
  },
  mounted(){
    this.$eventBus.$on('show-modal-workout-library', () => {
      this.isActive = true;
    });
    this.$eventBus.$on('close-modal-workout-library', () => {
      this.isActive = false;
    });
  },
  components: {
    WorkoutLibrary
  },
}
</script>
