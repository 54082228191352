<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card" style="width: auto;">
      <header class="modal-card-head">
        <h2 class="modal-card-title">{{ $t('workout.validate_workout') }}</h2>
      </header>

      <section class="modal-card-body">
        <section v-if="show === 'VALIDATE'" class="mb-4">
          <h3 class="title is-4" v-if="type==='WORKOUT'">{{ $t('workout.did_you_do_workout') }}</h3>
          <h3 class="title is-4" v-if="type==='TRAININGRIDE'">{{ $t('training_ride.did_you_do_training_ride') }}</h3>


          <WorkoutSummary v-if="type === 'WORKOUT'" :workout="workout"/>
          <TrainingRideBox class="w-50p m-auto" v-if="type === 'TRAININGRIDE'" :trainingRide="workout" :showButtons="false"/>


          <section class="p-5">
            <b-button @click.native="validateWorkout()" class="w-100p mt-4">{{ $t('general.yes') }}</b-button>
            <b-button v-if="workout && !workout.date.isToday()" @click.native="removeWorkoutAndRegeneratePlan()" class="w-100p mt-4">{{ $t('general.no') }}</b-button>
            <b-button @click.native="didAnotherRide()" class="w-100p mt-4">{{ $t('workout.did_another_ride')}}</b-button>
            <b-button v-if="workout && workout.date.isToday()" @click.native="notYet()" class="w-100p mt-4">{{ $t('workout.not_done_yet') }}</b-button>
            <b-button v-if="workout && workout.date.isToday()" @click.native="removeWorkoutAndRegeneratePlan()" class="w-100p mt-4">{{ $t('workout.not_training_today') }}</b-button>
          </section>
        </section>

        <section v-if="show === 'ADD_ACTIVITY'" class="mb-4">
          <h3 class="title is-4">{{ $t('workout.add_activity') }}</h3>

          <template v-for="(activity, i) in ugetActivitiesForDay(workout.date)">
            <b-button :key="i" v-if="!activity.bound_workout" @click.native="bindActivity(activity)" class="w-100p mt-4">
              {{ $t('workout.bind_with') + activity.name }}
            </b-button>
          </template>
          <b-button @click.native="uploadActivity()" class="w-100p mt-4">{{ $t('activity.upload_an_activity') }}</b-button>
          <b-button @click.native="manualActivity()" class="w-100p mt-4">{{ $t('workout.validate_without_file') }}</b-button>
          <b-button @click.native="waitActivity()" class="w-100p mt-4">{{ $t('activity.wait_for_activity') }}</b-button>
        </section>

        <section v-if="show === 'DID_ANOTHER_RIDE'">
          <b-button @click.native="removeWorkout(); newActivity()" class="w-100p mt-4">{{ $t('plan.delete_workout_add_activity') }}</b-button>
          <b-button @click.native="removeWorkout(); uploadActivity()" class="w-100p mt-4">{{ $t('plan.delete_workout_import_activity') }}</b-button>
          <b-button @click.native="removeWorkout(); waitActivity()" class="w-100p mt-4">{{ $t('plan.delete_workout_wait_activity') }}</b-button>
          <b-button @click.native="show = 'VALIDATE'" class="w-100p mt-4">{{ $t('general.back') }}</b-button>
        </section>
      </section>
      <footer class="modal-card-foot">
      </footer>
    </div>
  </b-modal>

</template>

<script>
import WorkoutSummary from '@/views/workout/WorkoutSummary.vue';
import TrainingRideBox from '@/views/calendar/TrainingRideBox.vue';
import { ObjectID } from 'bson';


export default{
  name: 'ValidateWorkout',
  data(){
    return {
      isActive: false,
      show: 'VALIDATE',
      workout: null,
      type: '',
    };
  },
  methods: {
    setWorkout(workout){
      this.workout = workout;
    },

    validateWorkout(){
      if(!this.workout.bound_activity)
        this.show = 'ADD_ACTIVITY';
    },

    timesOfWeek(planData, week){
      if(week instanceof this.LocalDate)
        week = week.dateFormat();

      if(planData.available_time_on_week[week])
        return planData.available_time_on_week[week];

      return {
        week_available_time: planData.week_available_time,
        max_week_available_time: planData.max_week_available_time,
        available_time_days: planData.available_time_days,
        training_type_days: planData.training_type_days,
      };
    },


    async didAnotherRide(){
      let week = this.workout.date.firstDayOfWeek().dateFormat();
      let activitiesDay = this.ugetActivitiesForDay(this.workout.date);
      if(activitiesDay.length){ //If there is at least already an activity the same day, just delete the workout and let user rate his activity
        this.removeWorkout();
        if(activitiesDay.filter(activity => !!activity.intensity_done).length > 0){ //If the activity is already rated, regenerate plan for the week
          const loadingComponent = this.$buefy.loading.open();
          await this.$store.dispatch('regenerateWeek', week);
          loadingComponent.close();
        }

        this.close();
      }else{
        this.show = 'DID_ANOTHER_RIDE';
      }
    },

    async newActivity(){
      this.$eventBus.$emit('show-modal-add-activity', { date: this.workout.date });
      this.close();
    },




    async removeWorkout(){
      try{
        if(this.type === 'WORKOUT'){
          await this.$store.dispatch('removeWorkout', this.workout);
        }else if(this.type === 'TRAININGRIDE'){
          await this.$store.dispatch('removeTrainingRide', this.workout);
        }
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
      }
    },

    //Remove workout and regenerate week plan
    async removeWorkoutAndRegeneratePlan(){
      const loadingComponent = this.$buefy.loading.open();
      try{
        let week = this.workout.date.firstDayOfWeek().dateFormat();

        await this.removeWorkout();

        if(this.workout.date.isToday()){  //If workout is from today, put today availability at 0 to prevent a new workout regeneration
          let trainingPlanData = JSON.parse(JSON.stringify(this.$store.getters.user.training_plan_data));
          let weekTime = this.timesOfWeek(trainingPlanData, week);
          weekTime.available_time_days[this.workout.date.dayOfWeek()] = 0;
          trainingPlanData.available_time_on_week[week] = weekTime;


          await this.$store.dispatch('updateTrainingPlanData', trainingPlanData); //update plan data
        }else{ //Just regenerate week if workout isn't from today
          await this.$store.dispatch('regenerateWeek', week);
        }
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
      }
      loadingComponent.close();
      this.close();
    },

    //Open upload activity modal
    uploadActivity(){
      this.$eventBus.$emit('show-modal-upload-activity');
      this.close();
    },


    //Add activity based on the workout to validate it
    async manualActivity(){
      try{
        let activity = {
          name: this.workout.name,
          date: this.workout.date,
          timestamp: this.workout.date,
          duration: 0,
          description: this.workout.description,
          type: this.workout.sport,
          data: { distance: 0, load: 0, total_ascent: 0 },
        };
        if(this.type === 'WORKOUT'){
          activity.duration = this.workout.duration;
          activity.data.load = this.workout.load || 0;
        }else if(this.type === 'TRAININGRIDE'){
          activity.duration = this.workout.estimated_duration;
          activity.data.distance = this.workout.estimated_distance || 0;
          activity.data.total_ascent = this.workout.estimated_elevation || 0;
        }
        await this.$store.dispatch('newActivity', activity);
        this.close();
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
      }
    },

    //Bind an activity with the workout
    async bindActivity(activity){
      try{
        if(this.type === 'WORKOUT'){
          await this.$store.dispatch('bindWorkoutActivity', { workout: this.workout, activity: activity });
        }else if(this.type === 'TRAININGRIDE'){
          await this.$store.dispatch('bindTrainingRideActivity', { training_ride: this.workout, activity: activity });
        }
        this.close();
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
      }
    },

    waitActivity(){
      this.close();
    },
    notYet(){
      this.close();
    },

    open(data){
      if(!data || !data.workout){
        return console.error('Validate Workout no data or no workout: ', data);
      }

      let workout = data.workout;
      this.setWorkout(workout);
      this.isActive = true;
      this.show = 'VALIDATE';
      this.type = data.type;
    },
    close(){
      this.isActive = false;
    }
  },

  mounted(){
    this.$eventBus.$on('show-modal-validate-workout', (data) => {
      this.open(data);
    });
    this.$eventBus.$on('close-modal-validate-workout', () => {
      this.close();
    });
  },

  components: {
    WorkoutSummary,
    TrainingRideBox,
  },
}
</script>

<style lang="scss" scoped>
</style>
