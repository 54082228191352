<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card" style="width: auto;">
      <header class="modal-card-head">
        <h3 class="title is-4">{{ $t('training_ride.plan_workout_or_training_ride') }}</h3>
      </header>
      <section class="modal-card-body">
        <p class="p-2 mb-3">{{ $t('training_ride.plan_workout_or_training_ride_desc') }}</p>
        <div class="columns">
          <div class="column">
            <button class="button box h-100p w-100p" @click="addTrainingRide">
              <p><b-icon icon="bike"/></p>
              <p>{{ $t('training_ride.training_ride') }}</p>
            </button>
          </div>
          <div class="column">
            <button class="button box h-100p w-100p" @click="addWorkout">
              <p><b-icon icon="alarm-plus"/></p>
              <p>{{ $t('workout.workout') }}</p>
            </button>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>

export default{
  name: 'plan-training',
  data(){
    return {
      date: this.LocalDate.today(),
      isActive: false,
    };
  },
  methods: {
    addTrainingRide(){
      this.isActive = false;
      this.$eventBus.$emit('show-modal-new-trainingride', { date: this.date });
    },
    addWorkout(){
      this.isActive = false;
      this.$eventBus.$emit('show-modal-plan-workout', { date: this.date });
    },
  },
  mounted(){
    this.$eventBus.$on('show-modal-plan-training', (data) => {
      this.isActive = true;
      this.date = data.date ? data.date : this.LocalDate.today();
    });
    this.$eventBus.$on('close-modal-plan-training', () => {
      this.isActive = false;
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
