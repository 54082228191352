<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card" style="width: auto;">
      <header class="modal-card-head">
        <h2 class="modal-card-title">{{ editCurrent ? $t('objective.edit_objective') : $t('objective.new_objective') }}</h2>
      </header>
      <section class="modal-card-body">
        <b-field grouped >
          <b-field :label="$t('general.name')" :type="{'is-danger': errors.has('name')}" :message="errors.get('name')">
            <b-input type="text" name="name" v-model="objective.name"/>
          </b-field>
          <b-field :label="$t('objective.priority')">
            <b-select v-model="objective.priority">
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
            </b-select>
          </b-field>
        </b-field>
        <b-field :label="$t('dates.date')" :type="{'is-danger': errors.has('date')}" :message="errors.get('date')">
          <LocalDatePicker v-model="objective.date" ref="date" required :minDate="new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())"/>
        </b-field>
        <b-field grouped group-multiline>
          <b-field :label="$t('objective.estimated_duration')">
            <DurationPicker v-model.number="objective.estimated_duration" :minutesIncrement="15"/>
          </b-field>
          <UnitInput v-model.number="objective.distance" unit="distance" :label="$t('metrics.distance')"/>
          <UnitInput v-model.number="objective.elevation" unit="height" :label="$t('metrics.elevation')"/>
        </b-field>
        <b-field :label="$t('objective.profile_type')" v-show="$store.getters.hasStandardAccess">
          <b-select v-model="objective.profile_type">
            <option value=""></option>
            <option value="sprinter">{{ $t('objective.sprinter') }}</option>
            <option value="puncher">{{ $t('objective.puncher') }}</option>
            <option value="climber">{{ $t('objective.climber') }}</option>
            <option value="rouleur">{{ $t('objective.rouleur') }}</option>
            <option value="grandfondo">{{ $t('objective.grandfondo') }}</option>
          </b-select>
        </b-field>
        <!--<b-field :label="$t('objective.skills')" grouped group-multiline>
          <b-select v-model="selectedSkill">
            <option value="sprint">{{ $t('objective.sprint') }}</option>
            <option value="anaerobic">{{ $t('objective.anaerobic') }}</option>
            <option value="max_aerobic">{{ $t('objective.max_aerobic') }}</option>
            <option value="threshold">{{ $t('objective.threshold') }}</option>
            <option value="endurance">{{ $t('objective.endurance') }}</option>
            <option value="flat">{{ $t('objective.flat') }}</option>
            <option value="hilly">{{ $t('objective.hilly') }}</option>
            <option value="climbing">{{ $t('objective.climbing') }}</option>
            <option value="timetrial">{{ $t('objective.timetrial') }}</option>
            <option value="stage">{{ $t('objective.stage') }}</option>
          </b-select>
          <p class="control">
            <button class="button" @click="addSkill">{{ $t('objective.add_skill') }}</button>
          </p>
          <b-taglist>
            <b-tag v-for="skill in objective.skills" :key="skill" closable @close="removeSkill(skill)"> {{ $t('objective.'+skill) }}</b-tag>
          </b-taglist>
        </b-field>-->
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="isActive=false">{{ $t('general.close') }}</button>
        <button class="button" type="button" @click="newObjective()">{{ editCurrent ? $t('general.update') : $t('general.send') }}</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import DurationPicker from '@/components/DurationPicker.vue';
import LocalDatePicker from '@/components/LocalDatePicker.vue';
import UnitInput from '@/components/UnitInput.vue';
import { ObjectID } from 'bson';


export default {
  name: 'newObjective',
  data() {
    return {
      objective: {
        id: new ObjectID(),
        date: this.LocalDate.today(),
        name: '',
        description: '',
        sport: 'cycling',
        priority: 'A',
        estimated_duration: 0,
        distance: 0,
        elevation: 0,
        skills: [],
        profile_type: '',
      },
      selectedSkill: '',
      editCurrent: false,
      isActive: false,
      errors: new Map(),
    };
  },
  methods: {
    addSkill(){
      if(this.selectedSkill && !this.objective.skills.includes(this.selectedSkill)){
        this.objective.skills.push(this.selectedSkill);
      }
    },
    removeSkill(skill){
      this.objective.skills.splice(this.objective.skills.indexOf(skill), 1);
    },

    check(){
      this.errors = new Map();
      if(!this.objective.name){
        this.errors.set('name', this.$t('errors.general.enter_name'));
      }
      if(!this.objective.date || this.objective.date < this.LocalDate.today()){
        this.errors.set('date', this.$t('errors.general.invalid_date'));
      }
      return this.errors.size <= 0;
    },

    newObjective(){
      if(!this.check())
        return;

      var path = this.editCurrent ? 'updateObjective' : 'newObjective';
      this.$store.dispatch(path, this.objective).then(() =>{
        this.isActive = false; //close modal when objective have been successfully sent
        this.objective.id = new ObjectID(); //change ID for future new objective sent
      }).catch(err => {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
         console.error(err);
      });
    },

    setObjective(data){
      var objective = data?.objective;
      if(objective){
        Object.assign(this.objective, objective);
        this.editCurrent = true;
      }else{
        this.objective.date = (data && data.date) ? data.date : this.LocalDate.today();
        this.editCurrent = false;
      }
    },
  },
  mounted(){
    this.$eventBus.$on('show-modal-new-objective', (data) => {
      this.setObjective(data);
      this.isActive = true;
    });
    this.$eventBus.$on('close-modal-new-objective', () => {
      this.isActive = false;
    });
  },
  components: {
    DurationPicker,
    LocalDatePicker,
    UnitInput,
  },
}
</script>

<style lang="scss" scoped>
</style>
