<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card" style="width: auto;" v-if="workout">
      <header class="modal-card-head">
        <h2 class="modal-card-title"> {{ workout.name }} </h2>
      </header>
      <section class="modal-card-body">
        <WorkoutSummary v-if="workout.steps.length > 0" v-bind:workout="workout"/>
        <p class="mt-5" v-html="$t('workout.export_help')"/>
      </section>

      <footer class="modal-card-foot">
        <button class="button" type="button" @click="isActive=false">{{ $t('general.close') }}</button>
        <button class="button" @click="exportZwo()">{{ $t('workout.export_to_zwift')}}</button>
        <button class="button" @click="exportErg()">Export .ERG</button>
        <button class="button" @click="exportMrc()">Export .MRC</button>
        <!--<button class="button" @click="exportFit()">Export .FIT</button>-->
      </footer>
    </div>
  </b-modal>
</template>

<script>
import WorkoutSummary from './WorkoutSummary.vue';
const workoutExports = require('@/../shared/workout/workoutExports.js');
import FileSaver from 'file-saver';


export default {
  name: 'workoutExporter',
  data() {
    return {
      workout: null,
      model: null,
      isActive: false,
    };
  },
  methods: {
    exportErg(){
      var content = workoutExports.toErg(this.workout, this.model);
      var blob = new Blob([content], {type: 'application/octet-stream'});
      FileSaver.saveAs(blob, this.workout.name+'.erg');
    },

    exportZwo(){
      var content = workoutExports.toZwo(this.workout, this.model);
      var blob = new Blob([content], {type: 'application/octet-stream'});
      FileSaver.saveAs(blob, this.workout.name+'.zwo');

      this.ftpMessage();
    },

    //Alert after export to update FTP on Zwift
    ftpMessage(){
      let ftp = this.model.threshold_power;

      //Check in local storage if message with same ftp have already been displayed
      if(ftp && ftp != parseInt(localStorage.getItem('export_ftp'))){
        this.$buefy.dialog.alert({
          title: this.$t('workout.zwift_ftp_title'),
          message: this.$t('workout.zwift_ftp_message', { ftp: ftp }),
        });
        localStorage.setItem('export_ftp', ftp); //update stored ftp
      }
    },

    exportMrc(){
      var content = workoutExports.toMrc(this.workout, this.model);
      if(content){
        var blob = new Blob([content], {type: 'application/octet-stream'});
        FileSaver.saveAs(blob, this.workout.name+'.mrc');
      }
    },

    exportFit(){
      var content = workoutExports.toFit(this.workout, this.model);
      console.log(content);
      if(content){
        var blob = new Blob([content], {type: 'application/octet-stream'});
        FileSaver.saveAs(blob, this.workout.name+'.fit');
      }
    },

    onOpen(data){
      this.workout = data.workout;
      this.model = this.ugetSetModel(this.LocalDate.now());
    },
  },
  mounted(){
    this.$eventBus.$on('show-modal-workout-exporter', (data) => {
      this.onOpen(data);
      this.isActive = true;
    });
    this.$eventBus.$on('close-modal-workout-exporter', () => {
      this.isActive = false;
    });
  },
  components: {
    WorkoutSummary,
  }
}
</script>

<style lang="scss">
</style>
