<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card w-auto">
      <header class="modal-card-head">
        <h3 class="title is-4">{{ $t('add_item.add_to_calendar') }}</h3>
      </header>
      <section class="modal-card-body">
        <div class="tile is-ancestor">
          <div class="tile is-vertical is-parent">
            <div class="tile is-child">
              <button class="button box h-100p w-100p" @click="addActivity">
                <p><b-icon icon="bike"/></p>
                <p>{{ $t('activity.activity') }}</p>
              </button>
            </div>
            <div class="tile is-child">
              <button class="button box h-100p w-100p" @click="uploadActivity">
                <p><b-icon icon="file-upload"/></p>
                <p>{{ $t('activity.upload_activity') }}</p>
              </button>
            </div>
            <div class="tile is-child">
              <button class="button box h-100p w-100p" @click="addMeasure">
                <p><b-icon icon="ruler"/></p>
                <p>{{ $t('measure.measure') }}</p>
              </button>
            </div>
          </div>
          <div class="tile is-vertical is-parent">
            <div class="tile is-child calendar-tour-4">
              <button class="button box h-100p w-100p" @click="addTraining">
                <p><b-icon icon="alarm-plus"/></p>
                <p>{{ $t('workout.training') }}</p>
              </button>
            </div>
            <div class="tile is-child calendar-tour-3">
              <button class="button box h-100p w-100p" @click="addObjective">
                <p><b-icon icon="flag-outline"/></p>
                <p>{{ $t('objective.objective') }}</p>
              </button>
            </div>
            <div class="tile is-child calendar-tour-2">
              <button class="button box h-100p w-100p" @click="changeWeekTime" :disabled="!$planExist()">
                <p><b-icon icon="calendar"/></p>
                <p>{{ $t('plan.change_week_time') }}</p>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>

export default{
  name: 'AddToCalendar',
  data() {
    return {
      isActive: false,
      date: this.LocalDate.today(),
    };
  },
  methods: {
    addActivity(){
      this.isActive = false;
      this.$eventBus.$emit('show-modal-add-activity', { date: this.date });
    },
    uploadActivity(){
      this.isActive = false;
      this.$eventBus.$emit('show-modal-upload-activity')
    },
    addMeasure(){
      this.isActive = false;
      this.$eventBus.$emit('show-modal-new-measure', { date: this.date });
    },
    addTraining(){
      this.isActive = false;
      if(!this.$store.getters.hasPremiumAccess)
        this.$eventBus.$emit('show-modal-new-trainingride', { date: this.date });
      else
        this.$eventBus.$emit('show-modal-plan-training', { date: this.date }); //Only allow to plan workout for premium users
    },
    addObjective(){
      this.isActive = false;
      this.$eventBus.$emit('show-modal-new-objective', { date: this.date });
    },
    changeWeekTime(){
      this.isActive = false;
      this.$eventBus.$emit('show-modal-training-time', this.date.firstDayOfWeek());
    },
    generateTrainingPlan(){
      this.isActive = false;
      this.$router.push('/generate_training_plan');
    },
  },
  mounted(){
    this.$eventBus.$on('show-modal-add-to-calendar', (data) => {
      this.isActive = true;
      this.date = (data && data.date) ? data.date : this.LocalDate.today();
    });
    this.$eventBus.$on('close-modal-add-to-calendar', () => {
      this.isActive = false;
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
