/*
 * Parse and transform a tcx file content into an activity with activity data
 * Steps:
 * Parse gpx buffer data into json data with xml parser
 * Get and check serial
 * Calculate activityData (arrays of data)
 * Create activity json and calculate data from activityData if needed
 * Generate map with render callback
 */

const convert = require('xml-js');
const activityUtil = require('./activityUtil');

const decoder = new TextDecoder();

function parseTcx(buffer){
  return convert.xml2js(decoder.decode((buffer)), {compact: true});
}

function toArray(data){
  if(Array.isArray(data))
    return data;
  return [data];
}






/****************************/
/** Activity from tcx data **/
/****************************/

function definedNumber(data){
  return data ? data : 0;
}

//Calc datas of activity from activityData
function getAllData(laps, activityData, lastModel, userInfos){
  var data = {
    distance: 0
  };
  laps.forEach(lap => {
    if(lap.DistanceMeters)
      data.distance += (parseFloat(lap.DistanceMeters._text)/1000) || 0;
    if(lap.Calories)
      data.calories = (definedNumber(data.calories) + parseInt(lap.Calories._text)) || 0;
  });

  if(activityData.speed.length > 0){
    data.avg_speed = 0;
    data.max_speed = 0;
    activityData.speed.forEach(speed => {
      data.max_speed = Math.max(data.max_speed, speed);
      data.avg_speed += speed;
    });
    data.avg_speed/= activityData.speed.length;
  }

  if(activityData.power.length > 0){
    data.avg_power = 0;
    data.max_power = 0;
    activityData.power.forEach(power => {
      data.max_power = Math.max(data.max_power, power);
      data.avg_power += power;
    });
    data.avg_power = Math.round(data.avg_power / activityData.power.length);
    data.normalized_power = activityUtil.calcNPower(activityData.power);
  }

  if(activityData.heart_rate.length > 0){
    data.avg_heart_rate = 0;
    data.max_heart_rate = 0;
    activityData.heart_rate.forEach(heart_rate => {
      data.max_heart_rate = Math.max(data.max_heart_rate, heart_rate);
      data.avg_heart_rate += heart_rate;
    });
    data.avg_heart_rate = Math.round(data.avg_heart_rate / activityData.heart_rate.length);
  }

  if(activityData.cadence.length > 0){
    data.avg_cadence = 0;
    data.max_cadence = 0;
    var count = 0;
    activityData.cadence.forEach(cadence => {
      data.max_cadence = Math.max(data.max_cadence, cadence);
      if(cadence){
        data.avg_cadence += cadence; //don't count zeroes in avg
        count++;
      }
    });
    data.avg_cadence= Math.round(data.avg_cadence/count);
  }

  if(activityData.altitude.length > 0){ //calc total ascent from all altitude points
    data.total_ascent = 0;
    data.total_descent = 0;
    for(let i=1; i<activityData.altitude.length; i++){
      var change = activityData.altitude[i] - activityData.altitude[i-1];
      if(change > 0)
        data.total_ascent += change;
      else
        data.total_descent -= change;
    }
  }


  activityUtil.calcGeneralActivityData(data, activityData, lastModel, userInfos);

  return data;
}

//Create the activity object
function generateActivity(laps, sport, name, date, timestamp, serial, activityData, userInfos, lastModel){
  if(!name)
    name = activityUtil.dateFormat(date) + ' activity';

  if(sport == 'biking')
    sport = 'cycling';

  var duration = 0;
  laps.forEach(lap => {
    duration += parseInt(lap.TotalTimeSeconds._text);
  })


  var activity =  {
    timestamp: timestamp,
    date: date,
    type: sport,
    name: name,
    duration: duration,
    serial_number: serial,
    data: getAllData(laps, activityData, lastModel, userInfos),
  };
  activityUtil.calcLoad(activity, lastModel, userInfos);
  return activity;
}



/********************************/
/** ActivityData from gpx data **/
/********************************/

//Check if tcx points in laps contains tokens
//tokens is a list of token that allow for deeper access (example: data->token1->token2)
function checkLaps(laps, tokens){
  for(var i=0; i<laps.length; i++){
    const points = toArray(laps[i].Track.Trackpoint);
    for(var j=0; j<points.length; j++){
      var data = points[j];
      toArray(tokens).forEach(token => {
        if(data)
          data = data[token];
      });
      if(data)
        return true;
    }
  }
  return false;
}

//Transform tcx points from every laps into arrays
//Check true by default, will return an empty array if no token is found in record (if false, no check and an array full of 0 will be returned)
//Call is a callback if a data change is needed
function arrayFromLaps(laps, tokens, check = true, call = val => val){
  var array = [];

  if(!check || checkLaps(laps, tokens)){
    laps.forEach(lap => {
      toArray(lap.Track.Trackpoint).forEach(point => {
        var data = point;
        toArray(tokens).forEach(token => {
          if(data)
            data = data[token];
        });
        array.push(call(data ? parseFloat(data._text) : 0));
      });
    });
  }

  return array;
}

//Calc pause points and length
//Pause happen on device pauses of activity and exact pause points are needed for some data calculation
function calcPause(laps){
  var pause = [];
  var i=0;
  var previousTime = 0;
  laps.forEach(lap => {
    toArray(lap.Track.Trackpoint).forEach(point => {
      var time = point.Time ? new Date(point.Time._text).getTime()/1000 : previousTime+1;
      var diff = time - previousTime;
      if(previousTime && diff > 1){
        pause.push(i-1);
        pause.push(diff);
      }
      previousTime = time;
      i++;
    });
  })
  return pause;
}

//Generate speed data from each points position
function getSpeeds(laps, distances, pauses){
  var speeds = [];//arrayFromLaps(laps, ['Extensions', 'ns3:TPX', 'ns3:Speed'], speed => speed*3.6);
  if(speeds.length == 0){ //if no speed data, need to gnenerate
    speeds = new Array(distances.length);
    speeds[0] = 0;
    for(let i=1; i<distances.length; i++){
      speeds[i] = (distances[i]-distances[i-1])*3600;
    }

    for(let i=0; i<pauses.length; i+=2){
      var index = pauses[i];
      speeds[index+1] = 0;
    }
  }
  return speeds;
}



//Generate activity data from laps
function generateActivityData(laps){
  var activityData = {};

  activityData.pause = calcPause(laps);

  activityData.position_lat = arrayFromLaps(laps, ['Position', 'LatitudeDegrees']);
  activityData.position_long = arrayFromLaps(laps, ['Position', 'LongitudeDegrees']);
  activityData.distance = arrayFromLaps(laps, 'DistanceMeters', true, dist => dist/1000);
  activityData.altitude = arrayFromLaps(laps, 'AltitudeMeters');
  activityData.speed = getSpeeds(laps, activityData.distance, activityData.pause);
  activityData.power = arrayFromLaps(laps, ['Extensions', 'ns3:TPX', 'ns3:Watts']);
  activityData.accumulated_power = activityUtil.calcAccumulatedPower(activityData.power);
  activityData.heart_rate = arrayFromLaps(laps, ['HeartRateBpm', 'Value']);
  activityData.cadence = arrayFromLaps(laps, 'Cadence');
  activityData.temperature = arrayFromLaps(laps, 'Temperature');
  activityData.left_torque_effectiveness = arrayFromLaps(laps, 'left_torque_effectiveness');
  activityData.right_torque_effectiveness = arrayFromLaps(laps, 'right_torque_effectiveness');
  activityData.left_pedal_smoothness = arrayFromLaps(laps, 'left_pedal_smoothness');
  activityData.right_pedal_smoothness = arrayFromLaps(laps, 'right_pedal_smoothness');
  activityData.left_right_balance = arrayFromLaps(laps, 'left_right_balance', true, bal => (bal == 0) ? 0 : bal.value);



  activityData.power_curve = activityUtil.powerCurveFromData(activityData.power, activityData.pause);
  activityData.heart_rate_curve = activityUtil.heartRateCurveFromData(activityData.heart_rate, activityData.pause);

  return activityData;
}










module.exports = async function(buffer, name, userInfos, serialCheckCallback = serial => false, lastModelCall = date => null, renderFunction = (lat, long) => null){
  var tcxData = parseTcx(buffer);
  var activity = toArray(tcxData.TrainingCenterDatabase.Activities.Activity)[0]; //in case there is multiple activity, only take the first

  var timestamp = new Date(activity.Id._text);
  var serial = timestamp.getTime();
  if(serialCheckCallback(serial))
    throw 'errors.activity.duplicate';

  var date = activityUtil.toLocalDate(timestamp);

  var laps = toArray(activity.Lap);

  var activityData = generateActivityData(laps);
  return { activity: generateActivity(laps, activity._attributes.Sport, name, date, timestamp, serial, activityData, userInfos, lastModelCall(date)), activityData: activityData, image: await renderFunction(activityData.position_lat, activityData.position_long) };
}
