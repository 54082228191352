/*
 * Parse and transform a gpx file content into an activity with activity data
 * Steps:
 * Parse gpx buffer data into json data with xml parser
 * Get and check serial
 * Calculate activityData (arrays of data)
 * Create activity json and calculate data from activityData if needed
 * Generate map with render callback
 */

const convert = require('xml-js');
const activityUtil = require('./activityUtil');

const decoder = new TextDecoder();

function parseGpx(buffer){
  return convert.xml2js(decoder.decode((buffer)), {compact: true});
}

function toArray(data){
  if(Array.isArray(data))
    return data;
  return [data];
}






/****************************/
/** Activity from gpx data **/
/****************************/

function definedNumber(data){
  return data ? data : 0;
}

//Calc datas of activity from activityData
function getAllData(points, activityData, lastModel, userInfos){
  var data = {
    distance: activityData.distance[activityData.distance.length-1]
  };
  if(activityData.accumulated_power)
    data.calories = Math.round(activityData.accumulated_power[activityData.accumulated_power.length-1]/1000) || 0;

  if(activityData.speed.length > 0){
    data.avg_speed = 0;
    data.max_speed = 0;
    activityData.speed.forEach(speed => {
      data.max_speed = Math.max(data.max_speed, speed);
      data.avg_speed += speed;
    });
    data.avg_speed/= activityData.speed.length;
  }

  if(activityData.power.length > 0){
    data.avg_power = 0;
    data.max_power = 0;
    activityData.power.forEach(power => {
      data.max_power = Math.max(data.max_power, power);
      data.avg_power += power;
    });
    data.avg_power = Math.round(data.avg_power / activityData.power.length);
    data.normalized_power = activityUtil.calcNPower(activityData.power);
  }

  if(activityData.heart_rate.length > 0){
    data.avg_heart_rate = 0;
    data.max_heart_rate = 0;
    activityData.heart_rate.forEach(heart_rate => {
      data.max_heart_rate = Math.max(data.max_heart_rate, heart_rate);
      data.avg_heart_rate += heart_rate;
    });
    data.avg_heart_rate = Math.round(data.avg_heart_rate / activityData.heart_rate.length);
  }

  if(activityData.cadence.length > 0){
    data.avg_cadence = 0;
    data.max_cadence = 0;
    var count = 0;
    activityData.cadence.forEach(cadence => {
      data.max_cadence = Math.max(data.max_cadence, cadence);
      if(cadence){
        data.avg_cadence += cadence; //don't count zeroes in avg
        count++;
      }
    });
    data.avg_cadence= Math.round(data.avg_cadence/count);
  }

  if(activityData.altitude.length > 0){ //calc total ascent from all altitude points
    data.total_ascent = 0;
    data.total_descent = 0;
    for(let i=1; i<activityData.altitude.length; i++){
      var change = activityData.altitude[i] - activityData.altitude[i-1];
      if(change > 0)
        data.total_ascent += change;
      else
        data.total_descent -= change;
    }
  }


  activityUtil.calcGeneralActivityData(data, activityData, lastModel, userInfos);

  return data;
}

//Create the activity object
function generateActivity(points, sport, name, date, timestamp, serial, activityData, userInfos, lastModel){
  if(!name)
    name = activityUtil.dateFormat(date) + ' activity';

  if(sport === 'biking')
    sport = 'cycling';

  var duration = points.length > 1 ? (new Date(points[points.length-1].time._text).getTime()-new Date(points[0].time._text).getTime())/1000 : 0;


  var activity = {
    timestamp: timestamp,
    date: date,
    type: sport,
    name: name,
    duration: duration,
    serial_number: serial,
    data: getAllData(points, activityData, lastModel, userInfos),
  };

  activityUtil.calcLoad(activity, lastModel, userInfos);
  return activity;
}



/********************************/
/** ActivityData from gpx data **/
/********************************/

//Check if gpx points contains tokens
//tokens is a list of token that allow for deeper access (example: data->token1->token2)
function checkPoints(points, tokens){
  for(var i=0; i<points.length; i++){
    var data = points[i];
    toArray(tokens).forEach(token => {
      if(data)
        data = data[token];
    });
    if(data)
      return true;
  }
  return false;
}

//Transform gpx points into arrays
//Check true by default, will return an empty array if no token is found in record (if false, no check and an array full of 0 will be returned)
//Call is a callback if a data change is needed
function arrayFromPoints(points, tokens, check = true, call = val => val){
  var array = [];

  if(!check || checkPoints(points, tokens)){
    points.forEach(point => {
      var data = point;
      toArray(tokens).forEach(token => {
        if(data)
          data = data[token];
      });
      array.push(call(data ? parseFloat(data._text) : 0));
    });
  }

  return array;
}

//Calc pause points and length
//Pause happen on device pauses of activity and exact pause points are needed for some data calculation
function calcPause(points){
  var pause = [];
  var i=0;
  var previousTime = 0;
  points.forEach(point => {
    var time = new Date(point.time._text).getTime()/1000;
    var diff = time - previousTime;
    if(previousTime && diff > 1){
      pause.push(i-1);
      pause.push(diff);
    }
    previousTime = time;
    i++;
  });
  return pause;
}

//Generate speed data from each points position
function getSpeeds(points, distances, pauses){
  var speeds = [];//arrayFromPoints(points, ['Extensions', 'ns3:TPX', 'ns3:Speed'], speed => speed*3.6);
  if(speeds.length == 0){ //if no speed data, need to generate
    speeds = new Array(distances.length);
    speeds[0] = 0;
    for(let i=1; i<distances.length; i++){
      speeds[i] = (distances[i]-distances[i-1])*3600;
    }

    for(let i=0; i<pauses.length; i+=2){
      var index = pauses[i];
      speeds[index+1] = 0;
    }
  }
  return speeds;
}

//return a1 if a1 is not empty, else return a2
function nonEmptyArray(a1, a2){
  if(a1.length != 0)
    return a1;
  return a2;
}


//Generate activity data from records
function generateActivityData(points){
  var activityData = {};

  activityData.pause = calcPause(points);

  activityData.position_lat = [];//arrayFromPoints(points, [], true, point => point._attributes.lat);
  activityData.position_long = [];//arrayFromPoints(points, [], true, point => point._attributes.lon);
  points.forEach(point => {
    activityData.position_lat.push(parseFloat(point._attributes.lat));
    activityData.position_long.push(parseFloat(point._attributes.lon));
  });

  activityData.distance = activityUtil.calcDistancesFromCoord(activityData.position_lat, activityData.position_long);
  activityData.altitude = arrayFromPoints(points, 'ele');
  activityData.speed = getSpeeds(points, activityData.distance, activityData.pause);
  activityData.power = arrayFromPoints(points, ['extensions', 'power']);
  activityData.accumulated_power = activityUtil.calcAccumulatedPower(activityData.power);
  activityData.heart_rate = nonEmptyArray(arrayFromPoints(points, ['extensions', 'gpxdata:hr']), arrayFromPoints(points, ['extensions', 'gpxtpx:TrackPointExtension', 'gpxtpx:hr']), arrayFromPoints(points, ['extensions', 'ns3:TrackPointExtension', 'ns3::hr']));
  activityData.cadence = nonEmptyArray(arrayFromPoints(points, ['extensions', 'gpxdata:cadence']), arrayFromPoints(points, ['extensions', 'gpxtpx:TrackPointExtension', 'gpxtpx:cadence']), arrayFromPoints(points, ['extensions', 'ns3:TrackPointExtension', 'ns3::cad']));
  activityData.temperature = nonEmptyArray(arrayFromPoints(points, ['extensions', 'gpxdata:atemp']), arrayFromPoints(points, ['extensions', 'gpxtpx:TrackPointExtension', 'gpxtpx:atemp']), arrayFromPoints(points, ['extensions', 'ns3:TrackPointExtension', 'ns3::atemp']));
  activityData.left_torque_effectiveness = [];//arrayFromPoints(points, 'left_torque_effectiveness');
  activityData.right_torque_effectiveness = [];//arrayFromPoints(points, 'right_torque_effectiveness');
  activityData.left_pedal_smoothness = [];//arrayFromPoints(points, 'left_pedal_smoothness');
  activityData.right_pedal_smoothness = [];//arrayFromPoints(points, 'right_pedal_smoothness');
  activityData.left_right_balance = [];//arrayFromPoints(points, 'left_right_balance', true, bal => (bal == 0) ? 0 : bal.value);



  activityData.power_curve = activityUtil.powerCurveFromData(activityData.power, activityData.pause);
  activityData.heart_rate_curve = activityUtil.heartRateCurveFromData(activityData.heart_rate, activityData.pause);

  return activityData;
}










module.exports = async function(buffer, name, userInfos, serialCheckCallback = serial => false, lastModelCall = date => null, renderFunction = (lat, long) => null){
  var gpxData = parseGpx(buffer).gpx;

  var timestamp = new Date(gpxData.metadata.time._text);
  var serial = timestamp.getTime();
  if(serialCheckCallback(serial))
    throw 'errors.activity.duplicate';

  var date = activityUtil.toLocalDate(timestamp);

  var points = toArray(gpxData.trk.trkseg.trkpt);

  var activityData = generateActivityData(points);
  return { activity: generateActivity(points, gpxData.trk.type._text, name, date, timestamp, serial, activityData, userInfos, lastModelCall(date)), activityData: activityData, image: await renderFunction(activityData.position_lat, activityData.position_long) };
}
