<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card">
      <FtpEstimator v-if="tool=='ftp_estimator'"/>
      <a class="tool-direct-link" v-if="tool" :href="'/tools/'+tool">{{ $t('tools.direct_link') }}</a>
    </div>
  </b-modal>
</template>

<script>
import FtpEstimator from './FtpEstimator';

export default {
  name: 'toolsmodal',
  data() {
    return {
      tool: null,
      isActive: false
    };
  },
  methods: {
    setTool(data){
      this.tool = data.tool;
    }
  },
  mounted(){
    this.$eventBus.$on('show-modal-tools', (data) => {
      this.setTool(data);
      this.isActive = true;
    });
    this.$eventBus.$on('close-modal-tools', () => {
      this.isActive = false;
    });
  },
  components: {
    FtpEstimator,
  }
}
</script>

<style scoped>
.tool-direct-link{
  float: right;
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
