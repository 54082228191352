<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <h2 class="modal-card-title"> {{ $t('activity.new_activity') }} </h2>
      </header>
      <section class="modal-card-body">
        <b-field :label="$t('activity.activity_name')" label-position="on-border">
            <b-input v-model="name"></b-input>
        </b-field>
        <section>
          <b-field>
            <b-upload v-model="files" multiple drag-drop @change.native="handleFileUpload()">
              <section class="section">
                <div class="content has-text-centered">
                  <p> <b-icon icon="upload" size="is-large"/> </p>
                  <p>{{ $t('general.dropfiles') }}</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div class="tags">
            <span v-for="(file, i) in files" :key="i" class="tag is-info"
            v-bind:class="{ 'is-info': status[i] == 'uploading' || status[i] == 'listed', 'is-danger': status[i] == 'error', 'is-success': status[i] == 'uploaded'}">
              {{file.name}}
              <b-icon v-if="status[i]=='uploading'" class="loader is-loading"/>
              <b-icon v-if="status[i]=='uploaded'" icon="check-circle"/>
              <span v-if="status[i]=='error'"><b-icon icon="alert-circle"/> {{ messages[i] }}</span>
              <button v-if="status[i]=='listed'" class="delete is-small" type="button" @click="removeFile(i)"/>
            </span>
          </div>
        </section>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="isActive=false">{{ $t('general.close') }}</button>
        <button class="button" type="button" @click="submitActivities()">{{ $t('general.send') }}</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import activityDataFromBuffer from './activityDataFromBuffer.js';

export default {
  name: 'uploadActivity',
  data() {
    return {
      name: "",
      duration: 0,
      type: "cycling",
      files: [],
      status: [],
      messages: [],
      isActive: false
    };
  },
  methods: {
    removeFile(i){
      this.files.splice(i,1);
      this.status.splice(i,1);
    },

    removeNotListedFiles(){
      this.files = this.files.filter((file, i) => i >= this.status.length || this.status[i] == 'listed' );

      //remove duplicates
      let seen = new Set();
      this.files = this.files.filter((file, i) => {
        if(seen.has(file.name))
          return false;
        seen.add(file.name);
        return true;
      });
    },

    handleFileUpload(){
      this.removeNotListedFiles();
      this.status = [];
      this.files.forEach(file => {
        this.status.push('listed');
      });
      this.files.sort((f1,f2) => f1.lastModified - f2.lastModified);
    },

    onError(index, err){
      this.$set(this.status, index, 'error');
      this.$set(this.messages, index, err);
    },

    getExt(file){
      return file.name.split('.').pop().toUpperCase();
    },

    async activityFromFile(file, buffer, name){
      var ext = this.getExt(file);
      var activity = activityDataFromBuffer(buffer, name, ext);
      if(activity)
        return activity;
      throw 'wrong file extension';
    },

    submitActivity(index, name=null){
      if(index >= this.files.length)
        return;

      this.$set(this.status, index, 'uploading');

      var reader = new FileReader();
      reader.onload = () => {
        var arrayBuffer = reader.result;
        this.activityFromFile(this.files[index], arrayBuffer, name, this.$store.getters.user.infos).then(data =>{
          return this.$store.dispatch('uploadActivity', data);
        }).then(() =>{
          this.$set(this.status, index, 'uploaded');
        }).catch(err => {
          console.error(err);
          this.onError(index, this.$t(err));
        }).finally(() => {
          this.submitActivity(index+1);
        });
      };

      reader.onerror = () => {
        this.onError(index, 'Couldn\'t load file');
        this.submitActivity(index+1);
      };
      reader.readAsArrayBuffer(this.files[index]);
    },

    submitActivities(){
      this.messages = new Array(this.files.length);
      this.submitActivity(0, (this.files.length == 1 ? this.name : null));
    }
  },
  mounted(){
    this.$eventBus.$on('show-modal-upload-activity', () => {
      this.isActive = true;
    });
    this.$eventBus.$on('close-modal-upload-activity', () => {
      this.isActive = false;
    });
  },
}
</script>

<style lang="scss">
</style>
