/*
 * Functions to exports workout to files for external use
 * ERG and MRC for home trainer
 * ZWO for Zwift
 * toGarmin for garmin API
 */
const workoutUtil = require('./workoutUtil.js');
const filledModel = require('./filledModel.js');
const $t = require('../translations/i18n.js');

//Convert duration in seconds to a duration in minutes
function durationValue(duration){
  var minutes = Math.floor(duration/60);
  var seconds = (duration%60)/60; //seconds to percent of 1 minute
  return (minutes+seconds).toFixed(2);
}


//Text description of text
//Step notes with RPE and Cadence info if needed
function stepDescription(step, rpe = false){
  var description = '';
  if(rpe || rpe === 0)
    description += `RPE: ${rpe} \n`;
  if(step.cadence_target)
    description += `Cadence: ${ step.cadence_target } RPM \n`;
  if(step.notes)
    description += step.notes;
  return description;
}

//For ERG and MRC files, list of description for each steps
function courseText(steps){
  var content = '[COURSE TEXT]\n';

  var start = 0;
  //For each step with a description, add it to the file in this form
  //Start time of the step, tab, description on a single line, (TODO: split into multiple message for more than one line ?)
  //tab, 15 (duration of message)
  for(var step of steps){
    var description = stepDescription(step);
    if(description && description.length > 0){
      content += `${ start }\t${ description.split('\n')[0] }\t15 \n`;
    }
    start += step.duration;
  }

  content += '[END COURSE TEXT]\n';
  return content;
}

//Workout to ERG file content
function toErg(workout, model){
  var content = "[COURSE HEADER]\n";
  content += "VERSION = 2.0\n";
  content += "UNITS = ENGLISH\n";
  content += `DESCRIPTION = ${ workout.description || 'VO2Fast workout' }\n`;
  content += `FILENAME = ${ workout.name } \n`;
  content += `FTP = ${ (model && model.threshold_power) || 200 }\n`;
  content += "MINUTES WATTS\n";
  content += "[END COURSE HEADER]\n";
  content += "[COURSE DATA]\n";

  var start = 0;
  var steps = workoutUtil.toSimpleSteps(workout.steps);
  //For each step, two line containing the power target and the start & end time
  steps.forEach(step => {
    var value = workoutUtil.powerValueFromStep(step, model);
    content += durationValue(start) + "  " + value +  "\n";
    start += step.duration;
    content += durationValue(start) + "  " + value + "\n";
  });

  content += "[END COURSE DATA]\n";

  content += courseText(steps);

  return content;
}

//Convert workout into MRC
//Same than ERG but instead of power target for each step, its a % of given ftp
function toMrc(workout, model){
  const ftp = (model && model.threshold_power) || 0;
  if(!ftp)
    return null;

  var content = "[COURSE HEADER]\n";
  content += "VERSION = 2.0\n";
  content += "UNITS = ENGLISH\n";
  content += `DESCRIPTION = ${ workout.description || 'VO2Fast workout' }\n`;
  content += `FILENAME = ${ workout.name } \n`;
  content += `FTP = ${ ftp }\n`;
  content += "MINUTES PERCENT\n";
  content += "[END COURSE HEADER]\n";
  content += "[COURSE DATA]\n";

  var start = 0;
  var steps = workoutUtil.toSimpleSteps(workout.steps);
  steps.forEach(step => {
    var value = workoutUtil.powerValueFromStep(step, model)*100/ftp;
    content += durationValue(start) + "  " + value +  "\n";
    start += step.duration;
    content += durationValue(start) + "  " + value + "\n";
  });

  content += "[END COURSE DATA]\n";

  content += courseText(steps);

  return content;
}


//Convert workout to ZWO (zwift file)
function toZwo(workout, model){
  const ftp = (model && model.threshold_power) || filledModel.defaultModel.threshold_power;

  var content = '<workout_file>\n';

  content += `  <name>${workout.name}</name>\n`;
  content += '  <author>VO2Fast</author>\n';
  if(workout.description)
    content += `  <description>${workout.description}</description>\n`;
  content += '  <category>VO2Fast Workouts</category>\n';
  content += '  <sportType>bike</sportType>\n';


  content += '  <workout>\n';
  var steps = workoutUtil.toSimpleSteps(workout.steps); //TODO: Zwift handle repeatitions
  for(var step of steps){
    //For each step, give duration and power target as %ftp, with cadence target if defined
    var value = workoutUtil.powerValueFromStep(step, model);
    var convertedStep = workoutUtil.convertStepWithModel(step, model, workout.target_unit);
    var cadenceText = step.cadence ?  `Cadence="${step.cadence_target}"` : '';
    content += `    <SteadyState Duration="${step.duration}" Power="${(value/ftp).toFixed(2)}" ${ cadenceText }`;
    //Display note for step if there is one
    //If prefered target isnt power, display a note with the true target (RPE or Heart rate)
    if((step.notes && step.notes.length > 0) || convertedStep.target_unit != 'power'){
      var timeoffset = 0;
      content += '>\n';
      if(convertedStep.target_unit != 'power'){
        const units = { heart_rate: $t('en', 'metrics.bpm'), rpe: $t('en', 'metrics.rpe') }; //Todo get language from user data
        var text = `${ $t('en', 'workout.target') }: ${ convertedStep.value } ${ units[convertedStep.target_unit] }`;
        content += `        <TextEvent timeoffset="${ timeoffset }" message="${ text }"/>\n`;
        timeoffset += 10;
      }
      if(step.notes && steps.notes.length > 0){
        content += `        <TextEvent timeoffset="${ timeoffset }" message="${ step.notes }"/>\n`;
        timeoffset += 10;
      }
      content += '    </SteadyState>\n';
    }else{
      content += '/>\n';
    }
  }
  content += '  </workout>\n';

  content += '</workout_file>';
  return content;
}


//Target unit to Garmin target type
function gTargetType(target_unit){
  if(target_unit === 'power')
    return 'POWER_3S';
  if(target_unit === 'heart_rate')
    return 'HEART_RATE';
  if(target_unit === 'rpe')
    return 'OPEN';
  return target_unit;
}

//Check if step must be tagged as a recovery step (for steps in zone 1)
function isStepRecovery(step, model){
  let thresholdPower = (model && model.threshold_power) ? model.threshold_power : filledModel.defaultModel.threshold_power;
  let powerValue = workoutUtil.powerValueFromStep(step, model);
  return powerValue < thresholdPower*0.56;
}

//Tag intensity of step
function stepIntensity(step, steps, model, depth){
  //First and last steps are warmup and cooldown
  if(depth == 0){
    if(step === steps[0])
      return 'WARMUP';
    if(step === steps[steps.length-1])
      return 'COOLDOWN';
  }

  //Other steps are either recovery or interval
  if(isStepRecovery(step, model))
    return 'RECOVERY';
  return 'INTERVAL';
}

//Convert workout steps into Garmin workout steps
function garminSteps(workout, steps, model, order = 0, depth = 0){
  var result = [];
  const workoutUnit = workout.target_unit === 'heart_rate' ? 'heart_rate' : 'power'; //Only allow heart rate or power as preferred target unit for step value
  for(var step of steps){
    if(step.type === 'STEP'){ //If step is normal step, convert it
      var convertedStep = workoutUtil.convertStepWithModel(step, model, workoutUnit, false); //Convert step (if necessary) with model to either hr or power
      var value = convertedStep.value; //Get target
      var target_unit = convertedStep.target_unit; //and target unit
      var intensity = stepIntensity(step, steps, model, depth); //step intensity definition

      var rpe = false;
      var cstep2 = workoutUtil.convertStepWithModel(step, model, workout.target_unit); //Use a second converted step for RPE.
      if(cstep2.target_unit === 'rpe') //When the step is a short Heart rate one, it can also be converted to rpe and this will provide both info to Garmin workout
        rpe = cstep2.value;

      var targetLow = value;
      var targetHigh = value; //Calculate target lower and upper bound for workout
      if(target_unit === 'power'){
        targetLow = Math.round(value*0.9);
        targetHigh = Math.round(value*1.1);
      }else if(target_unit === 'heart_rate'){
        targetLow = value-5;
        targetHigh = value+5;
      }

      //If step has start on target flag, add a step that wait for the target to get reach before starting it
      //Only work with power. If other target (hr, rpe), its a lap button step.
      if(step.start_lap_on_target_power){
        let durationValue = step.duration;
        //If power target, the target to reach to go to the next step will be the low value target
        //For above threshold target, and even lower value will be used (for sprints it allow to go to the next step faster at the start of the sprint)
        if(target_unit === 'power' && model.threshold_power)
          durationValue = targetLow > model.threshold_power ? Math.floor((model.threshold_power + targetLow)/2) : targetLow;

        result.push({
          type: 'WorkoutStep',
          stepId: null,
          stepOrder: order,
          intensity: intensity,
          description: stepDescription(step, rpe),
          durationType: target_unit === 'power' ? 'POWER_GREATER_THAN' : 'OPEN',
          durationValue: durationValue,
          durationValueType: null,
          targetType: target_unit === 'power' ? 'POWER' : 'OPEN',
          targetValue: target_unit === 'rpe' ? value : null,
          targetValueLow: target_unit !=='rpe' ? targetLow : null,
          targetValueHigh: target_unit !== 'rpe' ? targetHigh : null,
          targetValueType: null,
        });
        order++; //order is step number and is updated on each new garmin step
      }


      //push main step
      result.push({
        type: 'WorkoutStep',
        stepId: null,
        stepOrder: order,
        intensity: intensity,
        description: stepDescription(step, rpe),
        durationType: 'TIME',
        durationValue: step.duration,
        durationValueType: null,
        targetType: gTargetType(target_unit),
        targetValue: target_unit === 'rpe' ? value : null,
        targetValueLow: target_unit !=='rpe' ? targetLow : null,
        targetValueHigh: target_unit !== 'rpe' ? targetHigh : null,
        targetValueType: null,
      });
      order++;

      //If step has endlapbutton flag, add a step that wait for the lap button press to go to the next step
      if(step.end_lap_button){
        result.push({
          type: 'WorkoutStep',
          stepId: null,
          stepOrder: order,
          intensity: intensity,
          description: stepDescription(step, rpe),
          durationType: 'OPEN',
          durationValue: step.duration,
          durationValueType: null,
          targetType: gTargetType(target_unit),
          targetValue: target_unit === 'rpe' ? value : null,
          targetValueLow: target_unit !=='rpe' ? targetLow : null,
          targetValueHigh: target_unit !== 'rpe' ? targetHigh : null,
          targetValueType: null,
        });
        order++;
      }
    }else if(step.type === 'REPEAT'){ //If it's a step repeat
      var subSteps = garminSteps(workout, step.steps, model, order+1, depth+1); //Convert the steps contained in the step repeat
      result.push({
        type: 'WorkoutRepeatStep',
        stepId: null,
        stepOrder: order,
        repeatType: 'REPEAT_UNTIL_STEPS_CMPLT',
        repeatValue: step.repeatitions,
        steps: subSteps,
      });
      order += subSteps.length + 1;
    }
  }

  if(depth === 0){ //Add a freeride step at the end of the workout
    result.push({ // (to not have an auto end ride when workout is finished, user can continue riding)
      type: 'WorkoutStep',
      stepId: null,
      stepOrder: order,
      intensity: 'COOLDOWN',
      description: '',
      durationType: 'OPEN',
      durationValue: null,
      durationValueType: null,
      targetType: 'OPEN',
      targetValue: null,
      targetValueLow: null,
      targetValueHigh: null,
      targetValueType: null,
    });
  }


  return result;
}

//Convert workout to garmin workout
function toGarminWorkout(workout, model, update = false){
  var gworkout = {
    workoutName: workout.name,
    description: workout.description.substring(0, 1024),
    sport: 'CYCLING',
    workoutProvider: 'VO2Fast',
    workoutSourceId: 'VO2Fast',
    steps: garminSteps(workout, workout.steps, model), //convert steps
  };

  if(update && workout.garmin && workout.garmin.workoutId){ //If workout has already been pushed to garmin
    gworkout.workoutId = workout.garmin.workoutId; //Set workout ID (as it will probably be sent again for update after being converted)
  }

  return gworkout;
}



const WAHOOTARGETUNITS = { 'rpe': 'rpe', 'power': 'watts', 'heart_rate': 'hr', 'speed': 'speed'};

//Convert steps into wahoo intervals
function wahooSteps(steps, model){
  let result = [];

  for(let step of steps){
    if(step.type === 'STEP'){
      let convertedStep = workoutUtil.convertStepWithModel(step, model);

      let targets = [{ type: WAHOOTARGETUNITS[convertedStep.target_unit], low: convertedStep.value, high: convertedStep.value }];
      if(step.cadence_target)
        targets.push({ type: 'rpm', low: step.cadence_target, high: step.cadence_target });

      result.push({
        name: step.name,
        exit_trigger_type: 'time',
        exit_trigger_value: step.duration,
        targets: targets,
      });

      //TODO: add step until lap button is pressed
      if(step.end_lap_button){
      }
    }else if(step.type === 'REPEAT'){
      result.push({
        name: step.name,
        exit_trigger_type: 'repeat',
        exit_trigger_value: step.repeatitions,
        intervals: wahooSteps(step.steps),
      });
    }
  }

  return result;
}

function toWahooWorkout(workout, model, update = false){
  let wworkout = {
    header: {
      name: workout.name,
      version: '1.0.0',
      description: workout.description.substring(0, 5000),
      workout_type_family: 0, //cycling
    },
    intervals: wahooSteps(workout.steps, model)
  };


  let encodedWorkout = 'data:application/json;base64,'+ btoa(unescape(encodeURIComponent(JSON.stringify(wworkout)))) +'&';
  return encodedWorkout;
}


//Convert workout to fit file
//TODO
function toFitWorkout(workout, model){
  const fileHeader = Buffer.from([0x2E, 0x46, 0x49, 0x54, 0x0A]);

  const workoutData = [];

  var steps = workoutUtil.toSimpleSteps(workout.steps);
  for(var step of steps){
    //For each step, give duration and power target as %ftp, with cadence target if defined
    var value = workoutUtil.powerValueFromStep(step, model);
    var convertedStep = workoutUtil.convertStepWithModel(step, model, workout.target_unit);

    workoutData.concat([])

    /*writer.addStep({
      duration: convertedStep.duration, // Duration of the step in seconds
      target: {
        type: convertedStep.target_unit,
        value: convertedStep.value, // Power target in watts
      },
    });*/

  }

  const workoutBuffer = Buffer.from(workoutData);
  return Buffer.concat([fileHeader, workoutBuffer]);
}


/*console.log(toFitWorkout(
  {
    name: 'workout',
    steps: [
      { target: 50, target_unit: 'power', target_type: 'threshold', duration: 300 },
      { target: 100, target_unit: 'power', target_type: 'threshold', duration: 600 },
    ],
  },
  { threshold_power: 300, anaerobic_capacity: 20000, max_power: 1200 }
));*/

module.exports = {
  toErg,
  toMrc,
  toZwo,
  toGarminWorkout,
  toWahooWorkout,
  toFitWorkout,
}
