<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card" style="width: auto;">
      <header class="modal-card-head">
        <h2 class="modal-card-title">
          {{ editCurrent ? $t('training_ride.edit_training_ride') :
            (isOldRide ? $t('training_ride.old_training_ride') : $t('training_ride.new_training_ride')) }}
        </h2>
      </header>
      <section class="modal-card-body">
        <b-field :label="$t('general.name')" :type="{'is-danger': errors.has('name')}" :message="errors.get('name')">
          <b-input type="text" name="name" v-model="trainingRide.name"/>
        </b-field>
        <b-field :label="$t('dates.date')" :type="{'is-danger': errors.has('date')}" :message="errors.get('date')">
          <LocalDatePicker v-model="trainingRide.date" ref="date" required/>
        </b-field>
        <b-field grouped>
          <b-field :label="isOldRide ? $t('metrics.duration') : $t('training_ride.estimated_duration')">
            <DurationPicker v-model.number="trainingRide.estimated_duration" :minutesIncrement="15"/>
          </b-field>
          <UnitInput v-model.number="trainingRide.distance" unit="distance" :label="isOldRide ? $t('metrics.distance') : $t('training_ride.estimated_distance')"/>
          <UnitInput v-model.number="trainingRide.elevation" unit="height" :label="isOldRide ? $t('metrics.elevation') : $t('training_ride.estimated_elevation')"/>
        </b-field>

          <b-field :label="isOldRide ? $t('training_ride.intensity_done') : $t('training_ride.planned_intensity')" :type="{'is-danger': errors.has('intensity')}" :message="errors.get('intensity')">
            <b-select v-model="trainingRide.planned_intensity">
              <option value=""></option>
              <option value="easy">{{ $t('metrics.easy') }}</option>
              <option value="endurance">{{ $t('metrics.endurance') }}</option>
              <option value="medium">{{ $t('metrics.medium') }}</option>
              <option value="hard">{{ $t('metrics.hard') }}</option>
              <option value="very_hard">{{ $t('metrics.very_hard') }}</option>
            </b-select>
          </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="isActive=false">{{ $t('general.close') }}</button>
        <button class="button" type="button" @click="newTrainingRide()">{{ editCurrent ? $t('general.update') : $t('general.send') }}</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import UnitInput from '@/components/UnitInput.vue';
import LocalDatePicker from '@/components/LocalDatePicker.vue';
import DurationPicker from '@/components/DurationPicker.vue';
import { ObjectID } from 'bson';


export default {
  name: 'newTrainingRide',
  data() {
    return {
      trainingRide: {
        id: new ObjectID(),
        date: this.LocalDate.new(),
        name: '',
        description: '',
        sport: 'cycling',
        estimated_duration: 0,
        distance: 0,
        elevation: 0,
        planned_intensity: '',
      },
      selectedSkill: '',
      editCurrent: false,
      isOldRide: false,
      isActive: false,
      errors: new Map(),
    };
  },
  methods: {
    check(){
      this.errors = new Map();
      if(!this.trainingRide.name){
        this.errors.set('name', this.$t('errors.general.enter_name'));
      }
      if(!this.trainingRide.date){// || this.trainingRide.date < this.LocalDate.today()){
        this.errors.set('date', this.$t('errors.general.invalid_date'));
      }
      if(!this.trainingRide.planned_intensity){
        this.errors.set('intensity', this.$t('errors.training_ride.pick_intensity'));
      }
      return this.errors.size <= 0;
    },

    async newTrainingRide(){
      if(!this.check())
        return;

      var path = this.editCurrent ? 'updateTrainingRide' : 'newTrainingRide';
      try{
        await this.$store.dispatch(path, this.trainingRide);
        this.isActive = false; //close modal when training ride have been successfully sent
        this.trainingRide.id = new ObjectID(); //change ID for future workout sent

        //If training ride added in the past, remove generated workouts with no activity bound
        if(this.trainingRide.date < new Date()){
          for(let workout of this.ugetWorkoutsForDay(this.trainingRide.date)){
            if(workout.generated && !workout.bound_activity){
              await this.$store.dispatch('removeWorkout', workout);
            }
          }
        }
      }catch(err){
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t(err),
          position: 'is-bottom',
          type: 'is-danger'
        });
        console.error(err);
      }
    },

    setTrainingRide(data){
      var trainingRide = data.trainingRide;
      var date = data.date;
      if(trainingRide){
        Object.assign(this.trainingRide, trainingRide);
        this.editCurrent = true;
      }else if(date){
        this.trainingRide.date = date;
        this.editCurrent = false;
      }
      this.isOldRide = !!data.isOldRide;
    },
  },
  mounted(){
    this.$eventBus.$on('show-modal-new-trainingride', (data) => {
      this.setTrainingRide(data);
      this.isActive = true;
    });
    this.$eventBus.$on('close-modal-new-trainingride', () => {
      this.isActive = false;
    });
  },
  components: {
    UnitInput,
    LocalDatePicker,
    DurationPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
