<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card" style="width: auto;">
      <header class="modal-card-head">
        <h2 class="modal-card-title">{{ $root.isMobile() ? $t('plan.training_time') : $t('plan.this_week_training_time') }}</h2>
        <button type="button" class="delete" @click="isActive=false"/>
      </header>

      <section class="modal-card-body">
        <section class="mb-4">
          <b-field :label="$t('plan.selected_week')">
            <div class="m-auto">
              <b-button icon-right="chevron-left" @click="previousWeek()"/>
              <span>{{ week.toLocaleDateString() }}</span>
              <b-button icon-right="chevron-right" @click="nextWeek()"/>
            </div>
          </b-field>
        </section>
        <!--<section class="block columns is-flex is-centered" >
          <b-field>
            <template #label>
              {{ $t('plan.week_training_time') }}
              <Helper :label="$t('plan.help.week_training_time')"/>
            </template>
            <DurationPicker class="is-inline-block" v-model="weekTime" :minutesIncrement="15" :maxHours="40"/>
          </b-field>
        </section>-->


        <section class="block columns is-flex is-centered" >
          <b-field>
            <template #label>
              {{ $t('plan.max_week_training_time') }}
              <Helper :label="$t('plan.help.max_week_training_time')"/>
            </template>
            <DurationPicker class="is-inline-block" v-model="maxWeekTime" :minutesIncrement="15" :maxHours="40"/>
          </b-field>
        </section>

        <section class="block">
          <b-field grouped group-multiline position="is-centered">
            <template #label>
              {{ $t('plan.day_training_time') }}
              <Helper :label="$t('plan.help.day_training_time')"/>
              <p v-if="$store.getters.isBaseAccount" class="has-text-weight-normal" v-html="$t('plan.free_limit_days')"/><br/>

            </template>
            <template v-for="(i, day) in (0,7)">
              <b-field :label="$t('dates.week_days.'+day)" :key="day" grouped>
                <div class="is-flex is-flex-direction-column">
                  <DurationPicker v-model="daysTime[day]" :minutesIncrement="15" :maxHours="6"/>
                  <TrainingTypePicker v-model="trainingTypeDays[day]"/>
                </div>
              </b-field>
            </template>
          </b-field>
        </section>
      </section>

      <footer class="modal-card-foot">
        <b-button @click="updateWeekTime()">{{  $t('plan.regenerate_week') }}</b-button>
        <!--<b-button @click="updateGlobalWeekTime()">{{  $t('general.set_default') }}</b-button>-->
        <b-button @click="reloadDefaultWeekTime()">{{ $t('plan.reload_default_week_time')  }}</b-button>
      </footer>
    </div>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
      <div class="box">
        <h1 class="title is-2"> {{ $t('plan.updating_plan')}} </h1>
        <section class="block">
          <b-icon icon="sync" size="is-large" custom-class="mdi-spin-reverse"/>
        </section>
      </div>
    </b-loading>
  </b-modal>
</template>

<script>
import DurationPicker from '@/components/DurationPicker.vue';
import TrainingTypePicker from '@/components/TrainingTypePicker.vue';
import Helper from '@/components/Helper.vue';

export default{
  name: 'TrainingTimeStep',
  data(){
    return {
      isActive: false,
      week: this.LocalDate.thisWeek(),
      weekTime: 0,
      maxWeekTime: 0,
      daysTime: [0,0,0,0,0,0,0],
      trainingTypeDays: ['outdoor','outdoor','outdoor','outdoor','outdoor','outdoor', 'outdoor'],
      isLoading: false,
    };
  },
  methods: {
    //Check for base account that it's within the limit of 3 days and one indoor day
    checkForBaseAccount(){
      if(this.$store.getters.hasStandardAccess) //If not base account return
        return true;

      //Check number of training days and indoor days
      if(!(this.daysTime.filter(t => t > 0).length <= 3 && this.trainingTypeDays.filter(t => t === 'indoor').length <=1)){
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('errors.plan.free_limit_days'),
          position: 'is-bottom',
          type: 'is-danger'
        });
        return false;
      }
      return true;
    },

    nextWeek(){
      this.setWeek(this.week.addDay(7));
    },

    previousWeek(){
      if(this.week.addDay(-7) >= this.LocalDate.thisWeek())
        this.setWeek(this.week.addDay(-7));
    },

    setWeek(week){
      if(!week)
        week = this.LocalDate.thisWeek();

      this.week = week;

      const training_plan_data = this.$store.getters.user.training_plan_data;
      const available_time_on_week = training_plan_data.available_time_on_week[this.week.dateFormat()] || {};
      if(training_plan_data){
        this.weekTime = available_time_on_week.week_available_time != null ?
          available_time_on_week.week_available_time : training_plan_data.week_available_time || 0;
        this.maxWeekTime = available_time_on_week.max_week_available_time != null ?
          available_time_on_week.max_week_available_time : training_plan_data.max_week_available_time || 0;

        //Check available time days of the week first, if it exist fill day time with existing times
        //If not fill with the general availabilities instead
        if(available_time_on_week.available_time_days && available_time_on_week.available_time_days.length == 7){
          this.daysTime = available_time_on_week.available_time_days.map(t => t || 0);
        }else if(training_plan_data.available_time_days && training_plan_data.available_time_days.length == 7){
          this.daysTime = training_plan_data.available_time_days.map(t => t || 0);
        }

        //Check training types days of the week first, if it exist fill training types with the already existing ones
        //If not fill with the general training types days instead
        if(available_time_on_week.training_type_days && available_time_on_week.training_type_days.length == 7){
          this.trainingTypeDays = available_time_on_week.training_type_days.map(t => t || 'outdoor');
        }else if(training_plan_data.training_type_days && training_plan_data.training_type_days.length == 7){
          this.trainingTypeDays = training_plan_data.training_type_days.map(t => t || 'outdoor');
        }

      }
    },

    //If plan data have specific time for week return it, else return plan data as general time
    timesOfWeek(planData, week){
      if(week instanceof this.LocalDate)
        week = week.dateFormat();

      if(planData.available_time_on_week[week])
        return planData.available_time_on_week[week];
      return planData;
    },


    //Check if there is a workout to regenerate today
    isThereWorkoutToRegenerate(){
      if(!this.week.isThisWeek()) //If regeneration isnt for this week, no workouts
        return false;

      let todayWorkouts = this.ugetWorkoutsForDay(this.LocalDate.today());
      for(let workout of todayWorkouts){
        if(workout.generated && !workout.bound_activity) //Find a generated workout with no bound activity
          return true;
      }
      return false;
    },

    async updateWeekTime(){
      if(!this.checkForBaseAccount())
        return;

      this.isLoading = true;
      try{
        var trainingPlanData = this.$store.getters.user.training_plan_data;
        const week = this.week.dateFormat();

        const previousWeekTime = this.timesOfWeek(trainingPlanData, week);

        trainingPlanData.available_time_on_week[week] = {
          week_available_time: this.weekTime,
          max_week_available_time: this.maxWeekTime,
          available_time_days: this.daysTime,
          training_type_days: this.trainingTypeDays,
        };


        //Check if week time have changed
        if(this.compareTimes(this.timesOfWeek(trainingPlanData, week), previousWeekTime)){
          await this.$store.dispatch('regenerateWeek', this.week); //If no change regenerate week without updating plan data
        }else{
          await this.$store.dispatch('updateTrainingPlanData', trainingPlanData); //Else update plan data
        }

        this.onSuccess();
      }catch(err){
        this.onError(err);
      }
      this.isLoading = false;
    },

    async updateGlobalWeekTime(){
      if(!this.checkForBaseAccount())
        return;

      this.isLoading = true;
      try{
        var trainingPlanData = this.$store.getters.user.training_plan_data;
        trainingPlanData.week_available_time = this.weekTime || trainingPlanData.week_available_time;
        trainingPlanData.max_week_available_time = this.maxWeekTime || trainingPlanData.week_available_time;
        trainingPlanData.available_time_days = this.daysTime;
        trainingPlanData.training_type_days = this.trainingTypeDays;

        trainingPlanData = this.removeWeeksWithDefaultTime(trainingPlanData);

        await this.$store.dispatch('updateTrainingPlanData', trainingPlanData);

        this.onSuccess();
      }catch(err){
        this.onError(err);
      }
      this.isLoading = false;
    },

    async reloadDefaultWeekTime(){
      const trainingPlanData = this.$store.getters.user.training_plan_data;
      this.weekTime = trainingPlanData.week_available_time;
      this.maxWeekTime = trainingPlanData.max_week_available_time;
      this.daysTime = trainingPlanData.available_time_days;
      this.trainingTypeDays = trainingPlanData.training_type_days;

      await this.updateWeekTime();
    },

    //Compare 2 object containing week training time and return true if they have the same times
    compareTimes(times1, times2){
      if(!times1.training_type_days || !times2.training_type_days)
        return false;

      return times1.week_available_time === times2.week_available_time &&
        times1.max_week_available_time === times2.max_week_available_time &&
        times1.available_time_days.every((v,i) => v === times2.available_time_days[i]) &&
        times1.training_type_days.every((v,i) => v === times2.training_type_days[i]);
    },

    removeWeeksWithDefaultTime(trainingPlanData){
      var weeksToRemove = [];
      for(let [week, weekTime] of Object.entries(trainingPlanData.available_time_on_week)){
        if(this.compareTimes(weekTime, trainingPlanData))
          weeksToRemove.push(week);
      }
      for(let week of weeksToRemove)
        delete trainingPlanData.available_time_on_week[week];

      return trainingPlanData;
    },

    onSuccess(){
      this.$buefy.toast.open({
        duration: 2000,
        message: this.$t('plan.time_updated'),
        position: 'is-bottom',
        type: 'is-success'
      });
    },

    onError(err){
      console.error(err);
      this.$buefy.toast.open({
        duration: 3000,
        message: this.$t(err),
        position: 'is-bottom',
        type: 'is-danger'
      });
    }
  },
  mounted(){
    this.$eventBus.$on('show-modal-training-time', (week) => {
      this.setWeek(week);
      this.isActive = true;
    });
    this.$eventBus.$on('close-modal-training-time', () => {
      this.isActive = false;
    });
  },
  components: {
    DurationPicker,
    TrainingTypePicker,
    Helper,
  }
}
</script>

<style lang="scss" scoped>
</style>
