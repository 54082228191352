<!-- Local Date Picker -->
<!-- DatePicker that use a LocalDate object instead of a simple Date object as a model -->
<template>
  <b-datepicker v-model="date" icon="calendar" editable trap-focus :first-day-of-week="1"
    nearby-selectable-month-days :minDate="minDate" :maxDate="maxDate" @input="updateValue()"/>
</template>

<script>

export default{
  name: 'localdate-picker',
  props: {
    value: {
      type: Object,
      default: () => this.LocalDate.today(),
    },
    minDate: {
      type: Date,
      default: () => null,
    },
    maxDate: {
      type: Date,
      default: () => null,
    },
  },
  data(){
    return {
      date: new Date(),
    };
  },
  methods:{
    setDate(localDate){
      this.date = localDate.toDateObject();
    },

    getLocalDate(){
      if(!this.date)
        return null;
      return this.LocalDate.dayFromDate(this.date);
    },

    updateValue(){
      this.$emit('input', this.getLocalDate());
    },
  },
  mounted(){ this.setDate(this.value); },
  watch: {
    value(){ this.setDate(this.value); },
  },
};
</script>

<style lang="scss" scoped>
</style>
