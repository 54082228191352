<template>
  <b-modal v-model="isActive" has-modal-card trap-focus>
    <div class="modal-card w-auto">
      <header class="modal-card-head">
        <h3 class="title is-4">{{ $t('account.syncs.want_sync_now') }}</h3>
      </header>

      <section class="modal-card-body has-text-left">
        <p v-html="$t('account.syncs.guide_sync_now')" class="mb-5"/>
        <Syncs class="has-text-centered section box"/>
        <p>{{ $t('account.syncs.sync_later') }}</p>
        <p class="mt-1 mb-2">{{ $t('account.syncs.prefer_manual_import') }}</p>
        <b-button @click="$eventBus.$emit('show-modal-upload-activity')">{{ $t('activity.manually_upload')}}</b-button>
      </section>

      <footer class="modal-card-foot">
        <button class="button" type="button" @click="isActive=false">{{ $t('general.close') }}</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import Syncs from '@/views/account/syncs/Syncs.vue';

export default{
  name: 'SyncsModal',
  data(){
    return {
      isActive: false,
    };
  },
  methods: {

  },
  mounted(){
    this.$eventBus.$on('show-modal-syncs-if-no-syncs', () => {
      if(!this.$store.getters.hasSync)
        this.isActive = true;
    });
    this.$eventBus.$on('show-modal-syncs', () => {
      this.isActive = true;
    });
    this.$eventBus.$on('close-modal-syncs', () => {
      this.isActive = false;
    });
  },
  components: {
    Syncs,
  },
};
</script>

<style lang="scss" scoped>
</style>
