/*
 * Transform buffer of file content (fit, tcx or gpx) into an activity and activity data
 */
const activityFromFit = require('./activityFromFit');
const activityFromTcx = require('./activityFromTcx');
const activityFromGpx = require('./activityFromGpx');

//Buffer, name, fileType, userinfos for some data
//serialCheckCallback: to check if the activity exist before spending time into calculating all the data
//lastModelCall: must return the last model at a specific data (for data (like zones) calculation)
//renderFunction: call to generate a map image
module.exports = async function(buffer, name, fileType, userInfos, serialCheckCallback = serial => false, lastModelCall = date => null, renderFunction = (lat, long) => null){
  fileType = fileType.toUpperCase();
  switch(fileType){
    case 'FIT':
      return await activityFromFit(buffer, name, userInfos, serialCheckCallback, lastModelCall, renderFunction);
    case 'TCX':
      return await activityFromTcx(buffer, name, userInfos,  serialCheckCallback, lastModelCall, renderFunction);
    case 'GPX':
      return await activityFromGpx(buffer, name, userInfos, serialCheckCallback, lastModelCall, renderFunction);
    default:
      return null;
  }
}
